import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { hostConfig } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";





const DeleteAccount = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState('');
  const { t,i18n } = useTranslation();

  useEffect(() => {
    // Grab the accountId from localStorage when the component mounts
    const accountID = localStorage.getItem('userUuid'); // Ensure 'accountId' is the correct key
    if (accountID) {
      setAccountId(accountID);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!accountId) {
      alert('No account ID found.');
      return;
    }

    try {
      const response = await axios.delete(`${hostConfig.API_BASE}/patientdeleteaccount`);
      console.log(response.data);
      if (response.data.success) {
        alert('Account successfully deleted.');
        navigate('/'); // Navigate to home page
      } else {
        // Handle the case where success is false
        alert('Failed to delete the account. Please try again.');
      }
    } catch (error) {
      console.error('There was an error deleting the account:', error);
      alert('Failed to delete the account.');
    }
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="error" onClick={handleClickOpen}>
      {t('patientdashboard.accountdelete.button')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('patientdashboard.accountdelete.dialogtitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t('patientdashboard.accountdelete.dialog')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('patientdashboard.accountdelete.cancel')}</Button>
          <Button onClick={handleDelete} color="error">{t('patientdashboard.accountdelete.dialogbuttondelete')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteAccount;
