import React, { useState } from "react";
import { Card, CardContent, CardMedia, Typography, Grid, IconButton, Box } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./Symptoms.css";
import runaway from './images/13.png'
import violence from './images/12.png'
import cheating from './images/不倫.png'
import leftbehind from './images/劣等感.png'
import loneliness from './images/寂しさ.png'
import bingeeating from './images/過食.png'
import centerattention from './images/自己顕示欲.png'
import drinking from './images/drinking.png'
import meanwords from './images/罵倒.png'
import selfish from './images/自己中心的.png'
import overthinking from './images/overthinking.png'
import frustration from './images/frustration.png'
import { useTranslation } from 'react-i18next';

  

const SymptomList = () => {
  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };


  const Anger = t('Symptoms.Anger');
  const Drinking = t('Symptoms.Drinking');
  const Loneliness = t('Symptoms.Loneliness');
  const SelfHarm = t('Symptoms.SelfHarm');
  const Selfish = t('Symptoms.Selfish');
  const Overthinking = t('Symptoms.Overthinking');
  const LeftBehind = t('Symptoms.Loser');
  const AttentionSeeking = t('Symptoms.AttentionSeeking');
  const OverEating = t('Symptoms.OverEating');
  const Violence = t('Symptoms.Violence');
  const Cheating = t('Symptoms.Cheating');
  const Excuses = t('Symptoms.Excuses');

  const cardData = [
    { image: frustration, description: Anger, color: "#CAAACD", fontColor:"#FDF3DD"},
    { image: drinking, description: Drinking, color: "#CB962E",fontColor:"#FDF3DD" },
    { image: loneliness, description: Loneliness, color: "#EF601E",fontColor:"#FDF3DD" },
    { image: meanwords, description: SelfHarm, color: "#F1DFB6",fontColor:"#EF601E" },
    { image: selfish, description: Selfish, color: "#5D4B4B",fontColor:"#FDF3DD" },
    { image: overthinking, description: Overthinking , color: "#D6E8F7",fontColor:"#CAAACD"},
    { image: leftbehind, description: LeftBehind, color: "#CAAACD", fontColor:"#FDF3DD"},
    { image: centerattention, description: AttentionSeeking, color: "#EF601E",fontColor:"#FDF3DD" },
    { image: bingeeating, description: OverEating, color: "#CB962E",fontColor:"#FDF3DD" },
    { image: violence, description: Violence, color: "#F1DFB6",fontColor:"#EF601E" },
    { image: cheating, description: Cheating, color: "#5D4B4B",fontColor:"#FDF3DD" },
    { image: runaway, description: Excuses, color: "#D6E8F7",fontColor:"#CAAACD" },
    // Add more objects as needed

    
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const cardsPerRow = 3; // Number of cards per row
  const rowsPerPage = 2; // Number of rows per page
  const itemsPerPage = cardsPerRow * rowsPerPage; 
  const maxSlides = Math.ceil(cardData.length / itemsPerPage);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % maxSlides);
  };

  const handleBack = () => {
    setCurrentSlide((prev) => (prev - 1 + maxSlides) % maxSlides);
  };

  const startIndex = currentSlide * itemsPerPage;
  const visibleCards = cardData.slice(startIndex, startIndex + itemsPerPage);


  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ overflow: 'hidden',height: '53vh' }}>
    <IconButton onClick={handleBack} disabled={currentSlide === 0}>
      <ArrowBackIosNewIcon />
    </IconButton>
    <Grid container spacing={2} sx={{ flexGrow: 1, maxWidth: 600 }} justifyContent="center"> {/* Set a max width for the container */}
      {visibleCards.map((item, index) => (
        <Grid item xs={6} sm={4} key={index}> {/* xs=6 for 2 cards in a row on mobile */}
          <Card raised sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '10%' }}>
            <CardMedia
              component="img"
              sx={{ height: '140px', objectFit: 'cover', backgroundColor: item.color}} // Fixed height for all images
              image={item.image}
              alt={item.description}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography 
                variant="h6" 
                sx={{ color: '#0A6621', fontWeight: 'bold', textAlign: 'center' }}
              >
                {item.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    <IconButton onClick={handleNext} disabled={currentSlide === maxSlides - 1}>
      <ArrowForwardIosIcon />
    </IconButton>
  </Box>
  );
};

export default SymptomList;
