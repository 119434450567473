import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Pagination, CircularProgress, Paper } from '@mui/material';
import { saveAs } from 'file-saver';
import { hostConfig } from '../../../config';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/transaction-history`, {
          params: { page }
        });
        setTransactions(response.data.transactions);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionHistory();
  }, [page]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${hostConfig.API_BASE}/download-transactions`, { responseType: 'blob' });
      saveAs(response.data, 'transactions.csv');
    } catch (error) {
      console.error('Error downloading transactions:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Transaction History
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="transaction history table">
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.transaction_uuid}>
                    <TableCell>${(transaction.transaction_amount / 100).toFixed(2)}</TableCell>
                    <TableCell>{transaction.transaction_type}</TableCell>
                    <TableCell>{new Date(transaction.transaction_date).toLocaleString()}</TableCell>
                    <TableCell>{transaction.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 2 }}
        />
        <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleDownload}>
          Download CSV
        </Button>
      </Box>
    </Container>
  );
};

export default TransactionHistory;