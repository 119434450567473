import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AccountInformation from './AccountInformation';
import PatientSchedule from './PatientSchedule';
import ReviewPage from "./ReviewPage";
import DeleteAccount from "./AccountDeletion";
import SaveCCInfo from "./RegisterCardInfo";
import { useTranslation } from "react-i18next";




function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function PatientDashboard() {
  const [value, setValue] = React.useState(0);
  const { t,i18n } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500, mt: 10 }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label={t('patientdashboard.tabs.info')} {...a11yProps(0)} />
        <Tab label= {t('patientdashboard.tabs.schedule')} {...a11yProps(1)} />
        <Tab label= {t('patientdashboard.tabs.reviews')} {...a11yProps(2)} />
        <Tab label= {t('patientdashboard.tabs.payment')} {...a11yProps(3)} />
        <Tab label= {t('patientdashboard.tabs.delete')} {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0} className = 'AccountInformation' >
      <AccountInformation />
      </TabPanel>
      <TabPanel value={value} index={1} className = 'ScheduleTab'>
        <PatientSchedule />
      </TabPanel>
      <TabPanel value={value} index={2} className = 'TherapyReviews'>
        <ReviewPage />
      </TabPanel>
      <TabPanel value={value} index={3} className = 'Delete Account'>
        <SaveCCInfo />
      </TabPanel>
      <TabPanel value={value} index={4} className = 'Delete Account'>
        <DeleteAccount />
      </TabPanel>

    </Box>
  );
}
