import React, { useState, useEffect } from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { hostConfig } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, Snackbar } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';

const GRegistration = ({ preferredTimezone }) => {
    const [registered, setRegistered] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const registerWithGoogle = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                const response = await axios.post(`${hostConfig.API_BASE}/googlepatientregistration`, {
                    code: codeResponse.code,
                    preferredTimezone
                });

                if (response.data.success === true) {
                    console.log('Registration successful', response);
                    setRegistered(true);
                    setSnackbarOpen(true);
                    setTimeout(() => {
                        navigate("/patientlogin");
                    }, 2000);
                } else {
                    console.log('Registration failed: No response data');
                }
            } catch (error) {
                console.error('Error occurred during registration:', error);
            }
        },
        onError: (error) => console.log('Registration Failed:', error),
        flow: 'auth-code'
    });

    const logOut = () => {
        googleLogout();
        setRegistered(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div>
            {registered ? (
                <Button
                    variant="contained"
                    onClick={logOut}
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: '#DB4437',
                        '&:hover': {
                            backgroundColor: '#C23321',
                        },
                    }}
                >
                    {t('googleregistration.logout')}
                </Button>
            ) : (
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={() => registerWithGoogle()}
                    fullWidth
                    sx={{
                        color: '#757575',
                        backgroundColor: '#ffffff',
                        borderColor: '#dadce0',
                        textTransform: 'none',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 500,
                        fontSize: '14px',
                        height: '40px',
                        '&:hover': {
                            backgroundColor: '#f1f3f4',
                            borderColor: '#dadce0',
                        },
                    }}
                >
                    {t('googleregistration.submit')}
                </Button>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Account created successfully! Redirecting to login..."
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </div>
    );
};

export default GRegistration;