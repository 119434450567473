import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';
import Button from '@mui/material/Button';
import { ScheduleSend } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";



// Define your Firebase configuration first
const firebaseConfig = {
    apiKey: "AIzaSyAfrKlyWtplvug2Drol81m5pUTXwDdpEDw",
    authDomain: "ascre-65d72.firebaseapp.com",
    databaseURL: "https://ascre-65d72-default-rtdb.asia-southeast1.firebasedatabase.app", // Added database URL
    projectId: "ascre-65d72",
    storageBucket: "ascre-65d72.appspot.com",
    messagingSenderId: "243468257698",
    appId: "1:243468257698:web:2f9faeeff618bce38b4afb",
    measurementId: "G-H5YNGZ8LKT"
};

// Initialize Firebase with the configuration
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);


function CalendarSender() {
  const { t,i18n } = useTranslation();
    const therapist_uuid = localStorage.getItem('userUuid');
    const session_uuid = localStorage.getItem('session_uuid');
    //console.log("session_uuid ",session_uuid);
    //const therapist_uuid = 'SomeUuIDsequeENCSD;j';
    //const session_uuid = 'SomeUuIDF0RSesSiOn';
   // console.log("therapist_uuid",therapist_uuid);
  


  const handleClick = () => {
    // Example object to send
    const objectToSend = { 
      message: 'Hello from Sender!',
      schedule:true,
      therapist_uuid:therapist_uuid, 
      session_uuid:session_uuid };

    console.log(objectToSend);

    // Write to Firebase Realtime Database
    set(ref(database, `session/${session_uuid}`), objectToSend);
  };

  return (
    <div>
    <IconButton  onClick={handleClick} color="primary">
    <ScheduleSend  />
    </IconButton>
    
    </div>
  );
}

export default CalendarSender;
