import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "./languagebutton";
import LogoutButton from "../LogOut";

// Add this CSS to your component or in a separate CSS file
const navbarStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1030,
};

const dropdownStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
};

function AllNavBar() {
  const { t } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="lg" style={navbarStyle}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Ascre</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Item>
              <LanguageSwitch />
            </Nav.Item>
            <NavDropdown title={t("navbar.Menu")} id="collasible-nav-dropdown">
              <NavDropdown.Item>
                <LinkContainer to="/">
                  <Nav.Link>{t("navbar.Searchtherapist")}</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <LinkContainer to="/">
                  <LogoutButton />
                </LinkContainer>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <LinkContainer to="/">
                  <Nav.Link>{t("navbar.Aboutus")}</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <LinkContainer to="/patientlogin">
                  <Nav.Link>{t("navbar.Login")}</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <LinkContainer to="/therapistlogin">
                  <Nav.Link>{t("navbar.Therapistlogin")}</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <LinkContainer to="/therapistregister">
                  <Nav.Link>{t("navbar.TherapistRegistration")}</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AllNavBar;