import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';

const LegalDisclosure = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          特定商取引法に基づく表記
        </Typography>
        <List>
          <ListItem divider>
            <ListItemText primary="会社名" secondary="株式会社Ascre" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="運営責任者" secondary="山崎幸太" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="住所" secondary="東京都世田谷区三軒茶屋1-2-19" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="連絡先" secondary="support@ascreforme.com" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="手数料について" secondary="売り上げの5％" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="サービスのキャンセル" secondary="利用者は、サービスの購入後、7日以内であれば、プラットフォームにサービスのキャンセルを申請することができます。キャンセルが承認されると、利用者は支払った料金が全額返金されます。サービスのキャンセルは、プラットフォームのウェブサイトまたはアプリの「マイアカウント」ページから行うことができます。" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="返金処理" secondary="返金処理は、通常、返金申請後3営業日以内に完了します。" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="決済期間" secondary="クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3 日以内にお振り込みいただく必要があります。" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="販売価格" secondary="各カウンセラー・セラピストに設定されています。各カウンセラーの価格をご確認ください。" />
          </ListItem>
          <ListItem divider>
            <ListItemText primary="支払方法" secondary="クレジットカード、Stripe" />
          </ListItem>
          <ListItem>
            <ListItemText primary="法的遵守" secondary="すべての取引は日本の法律および規制に準拠して行われます。" />
          </ListItem>
        </List>
      </Paper>
    </Container>
  );
}

export default LegalDisclosure;
