import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box } from '@mui/material';
import { hostConfig } from "../../config.js";
import axios from 'axios';
import AccountEdit from "./AccountInfoEdit.js";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";










const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AccountInformation = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [patientInfo, setPatientInfo] = React.useState([]);
  const { t,i18n } = useTranslation();


  const handleClickOpen = () => {
    setOpen(true);
  };



  useEffect(() => {
    async function fetchPatientInfo() {
        try {
          const response = await axios.get(`${hostConfig.API_BASE}/getpatientuuid`); 
          console.log(response.data);
          setPatientInfo(response.data[0]);
        } catch (error) {
          console.error('Error fetching patient information:', error);
        }
      }
  
      fetchPatientInfo();
    }, []);


    console.log(patientInfo,"consolog patientinfo")
  

  return (
    <Container maxWidth="sm" >

      <Typography variant="h4" align="center" gutterBottom>
      {t('patientdashboard.accountinfo.title')}
        
      </Typography>
      <Box>
      <p>{t('patientdashboard.accountinfo.firstname')}: {patientInfo.first_name}</p>
      <p>{t('patientdashboard.accountinfo.lastname')}: {patientInfo.last_name}</p>
        <p>{t('patientdashboard.accountinfo.email')}: {patientInfo.email}</p>
        <p>{t('patientdashboard.accountinfo.phonenumber')}: {patientInfo.phone_number}</p>
        <Button 
        variant="contained" 
        color="primary" 
        type="submit" 
        onClick={handleOpen}
        >
            {t('patientdashboard.accountinfo.edit')}
          </Button>
          <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >


          </BootstrapDialog>
  

      </Box>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <AccountEdit patientInfo = {patientInfo}/>
          </Typography>
        </DialogContent>

      </BootstrapDialog>
    </Container>






  );
};

export default AccountInformation;
