import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };
    return (
        <footer className="footer">
            <div className="footer-links">
                <Link to="/companyinformation" className="footer-link">
                    {t('Footer.Company')}
                </Link>
                <Link to="/termsofuse" className="footer-link">
                {t('Footer.ToS')}
                </Link>
                <Link to="/disclosure" className="footer-link">
                {t('Footer.Disclosure')}
                </Link>
            </div>
            <div className="footer-email">
            {t('Footer.Support')}: <a href="mailto:support@ascreforme.com" className="footer-link">support@ascreforme.com</a>
            </div>
        </footer>
    );
};

export default Footer;
