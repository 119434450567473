import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, TextField, Button, Rating, Box, Grid,textfiel } from '@mui/material';
import { hostConfig } from '../../config';
import { useTranslation } from "react-i18next";





/*example code to test
        const mockTherapist = [{
            name: "Dr. Jane Doe",
            uuid: "therapist-uuid",
            sessionUuid: "session-uuid",
            session_type:"first_impression",
            date: "2023/12/23"
        },
        {
            name: "Dr. John Smith",
            uuid: "therapist-uuid-2",
            sessionUuid: "session-uuid-2",
            session_type: "repeating_review",
            date: "2023/12/24"
        }];*/

const ReviewPage = () => {
    const [therapists, setTherapists] = useState([]);
    const [firstImpression, setFirstImpression] = useState('');
    const [judged, setJudged] = useState('');
    const [comfortLevel, setComfortLevel] = useState();
    const [helpfulness, setHelpfulness] = useState();
    const [clarity, setClarity] = useState('');
    const [lookingFor, setLookingFor] = useState('');
    const [general, setGeneral] = useState('');
    const [generalRepeat, setGeneralRepeat] = useState('');
    const [repeatHelpfulness, setRepeatHelpfulness] = useState();
    const [repeatImpression, setRepeatImpression] = useState('');
    const [repeatTherapist, setRepeatTherapist] = useState('');
    const { t,i18n } = useTranslation();
    




    useEffect(() => {
        // Define an async function to fetch data
        const fetchData = async () => {
          try {
            // Attempt to get data from the endpoint
            const response = await axios.get(`${hostConfig.API_BASE}/patientcomments`);
            // If successful, set the data in state
            setTherapists(response.data);
            // Optionally, log the response data to the console for debugging
            console.log('Fetched therapists data:', response.data);
          } catch (error) {
            // Log an error message if the request fails
            console.error('Error fetching therapist data:', error);
            // You may want to set some error state here as well
          }
        };
      
        // Call the fetchData function
        fetchData();
      }, []); // The empty array ensures this
      


    const questionBoxStyle = {
        border: '1px solid lightgray',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start' // Aligns content to the left
    };

    const impressionScale = [
        t('patientdashboard.review.impressionscale1'),
        t('patientdashboard.review.impressionscale2'),
        t('patientdashboard.review.impressionscale3'),
        t('patientdashboard.review.impressionscale4'),
        t('patientdashboard.review.impressionscale5'),
    ];
    const helpfulnessScale = [
        t('patientdashboard.review.helpfulnessscale1'),
        t('patientdashboard.review.helpfulnessscale2'),
        t('patientdashboard.review.helpfulnessscale3'),
        t('patientdashboard.review.helpfulnessscale4'),
        t('patientdashboard.review.helpfulnessscale5'),
        
    ];
    
    const handleSubmit = () => {
        // Data to be sent
        const session_uuid = therapists.uuid; 
        const therapist_uuid = therapists.therapist_uuid; 
        const patient_uuid = therapists.patient_uuid; 
        const reviewData = {
            firstImpression, // Assuming 'firstImpression' is defined
            helpfulness,     // Assuming 'helpfulness' is defined
            general,         // Assuming 'general' is defined
            lookingFor,      // Assuming 'lookingFor' is defined
            session_uuid,
            patient_uuid,
            therapist_uuid,
            repeatHelpfulness,
            repeatImpression,
            generalRepeat,
            repeatTherapist,
            comment_type: repeatHelpfulness ? 'repeat' : 'first_impression',
            commenter_type: "patient"

        };
    
        // Endpoint URL of your backend service
        console.log("review data print",reviewData);
    
        // Sending a POST request to the backend
        axios.post(`${hostConfig.API_BASE}/commentupdate`, reviewData)
            .then(response => {
                console.log("printingresponse", response.data);
                alert('Review Submitted!');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const renderFirstImpressionCard = (therapist) => ( 
    <Card>
        <CardContent>
            <Typography variant="h5" >
            {t('patientdashboard.review.title')} {therapist.first_name & " "& therapist.last_name}
            </Typography>
            <Typography variant="h6" gutterBottom>
            {t('patientdashboard.review.date')} {therapist.booked_session_date_timestamp}
            </Typography>
            <Typography variant="body1" gutterBottom>
            {t('patientdashboard.review.description1')}<br/>
            {t('patientdashboard.review.description2')}
            </Typography>

            {/*I know I'm supposed to change the firstimpression but got lazy.  comfort level = first impression */} 
            <Box style={questionBoxStyle}>
                <Typography variant="body1" align="left">
                {t('patientdashboard.review.comfort')}
                </Typography>
                <Rating
                    required
                    name="first-impression"
                    value={firstImpression}
                    precision={1}
                    onChange={(event, newValue) => setFirstImpression(newValue)}
                />
                <Typography variant="body2" color="textSecondary">
                    {impressionScale[Math.round(firstImpression) - 1]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                {t('patientdashboard.review.helpfulness')}
                </Typography>
                <Rating
                    required
                    name="helpfulness"
                    value={helpfulness}
                    precision={1}
                    onChange={(event, newValue) => setHelpfulness(newValue)}
                />
                <Typography variant="body2" color="textSecondary">
                    {helpfulnessScale[Math.round(helpfulness) - 1]}
                </Typography>
            </Box>
            

            <TextField
            
                label={t('patientdashboard.review.didyouget')}
                multiline
                fullWidth
                margin="normal"
                value={lookingFor}
                onChange={(e) => setLookingFor(e.target.value)}
            />
            <TextField
                label={t('patientdashboard.review.leavereview')}
                multiline
                fullWidth
                margin="normal"
                value={general}
                onChange={(e) => setGeneral(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('patientdashboard.review.submit')}
            </Button>
        </CardContent>
    </Card>
    );

    const renderRepeatingReviewCard = (therapist) => (
        <Card key={therapist.session_uuid}>
        <CardContent>
            <Typography variant="h5">
            {t('patientdashboard.review.therapistreview')} {therapist.first_name &&" "&&therapist.last_name}
            </Typography>
            <Typography variant="h6" gutterBottom>

            </Typography>
            <Typography variant="body2" gutterBottom style={{ fontSize: '0.8rem', color: 'gray' }}>
            {t('patientdashboard.review.therapistdescription1')}
                <br/>{t('patientdashboard.review.therapistdescription2')}
                {t('patientdashboard.review.therapistdescription3')}
                <br/> {t('patientdashboard.review.therapistdescription4')}
            </Typography>

            <Box style={questionBoxStyle}>
                <Typography variant="body1" align="left">
                {t('patientdashboard.review.therapistcomfort')}
                </Typography>
                <Rating
                required
                    name="repeat-impression"
                    value={repeatImpression}
                    precision={1}
                    onChange={(event, newValue) => setRepeatImpression(newValue)}
                />
                <Typography variant="body2" color="textSecondary">
                    {impressionScale[Math.round(repeatImpression) - 1]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                {t('patientdashboard.review.therapisthelpful')}
                </Typography>
                <Rating
                required
                    name="repeat-helpfulness"
                    value={repeatHelpfulness}
                    precision={1}
                    onChange={(event, newValue) => setRepeatHelpfulness(newValue)}
                />
                <Typography variant="body2" color="textSecondary">
                    {helpfulnessScale[Math.round(helpfulness) - 1]}
                </Typography>
            </Box>
            <TextField
                label={t('patientdashboard.review.therapistrepeat')}
                multiline
                fullWidth
                margin="normal"
                value={repeatTherapist}
                onChange={(e) => setRepeatTherapist(e.target.value)}
            />
            <TextField
                label={t('patientdashboard.review.therapistgeneral')}
                multiline
                fullWidth
                margin="normal"
                value={generalRepeat}
                onChange={(e) => setGeneralRepeat(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={() => handleSubmit(therapist)}>
            {t('patientdashboard.review.submit')}
            </Button>
        </CardContent>
    </Card>
    );


    const renderReviewCard = (therapist) => {
        if (therapist.session_type === "first_impression") {
            return renderFirstImpressionCard(therapist);
        } else if (therapist.session_type === "repeating_impression") {
            return renderRepeatingReviewCard(therapist);
        }
    };


    return (
        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Grid container spacing={2}>
          {therapists && therapists.length > 0 && therapists.map(therapist => (
            <Grid item xs={12} sm={6} key={therapist.session_uuid}>
              {renderReviewCard(therapist)}
            </Grid>
          ))}
        </Grid>
      </div>

    );
}

export default ReviewPage;









