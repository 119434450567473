import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import { useTranslation } from 'react-i18next';


const JourneyGrid = () => {
  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const Journeysubtitle1 = t('Journey.Journeysubtitle1')
  const Journeysubtitle2 = t('Journey.Journeysubtitle2')
  const Journeysubtitle3 = t('Journey.Journeysubtitle3')
  const Journeysubtitle4 = t('Journey.Journeysubtitle4')
  const Journeydescription1 = t('Journey.Journeydescription1')
  const Journeydescription2 = t('Journey.Journeydescription2')
  const Journeydescription3 = t('Journey.Journeydescription3')
  const Journeydescription4 = t('Journey.Journeydescription4')




  const squares = new Array(16).fill(null);
  const sentencePairs = [
    { first: Journeysubtitle1, second: Journeydescription1 },
    { first: Journeysubtitle2, second: Journeydescription2 },
    { first: Journeysubtitle3, second: Journeydescription3 },
    { first: Journeysubtitle4, second: Journeydescription4 },
    // Add more pairs if needed
  ];

  const imageData = [
    { src: image1, alt: "step 1" },
    { src: image2, alt: "Step 2" },
    { src: image3, alt: "Step 3" },
    { src: image4, alt: "Step 4" },
    // ... Add more objects if you have more than 4 images
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid container spacing={2} justifyContent="center">
        {squares.map((_, index) => {
          // First row - display an image
          if (index < 4) {
            const image = imageData[index]; // Get the image object based on the current index
            return (
              <Grid item xs={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paper 
                  sx={{ 
                    border: '2px solid black',
                    borderRadius: '10%', // Circled corners
                    width: 130, // Set the width to match the height for a square
                    height: 130, // Adjust the size of the square as needed
                    backgroundColor: 'white', // White background
                    display: 'flex', // Using flex to center the image
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    overflow: 'hidden' // Prevents the image from spilling outside the border-radius
                  }} 
                  elevation={0}
                >
                  <img
                    src={image.src} // Use the src from the image object
                    alt={image.alt} // Use the alt text from the image object
                    style={{ 
                      maxWidth: '80%', // Adjust as needed to scale the image size
                      maxHeight: '80%', // Adjust as needed to scale the image size
                      objectFit: 'contain' // Keeps image aspect ratio
                    }}
                  />
                </Paper>
              </Grid>
            );
          }

          // Second row - display another image
          else if (index >= 4 && index < 8) {
            return (
              <Grid item xs={3} key={index}>
                <Paper sx={{  padding: 1, height: '100px', position: 'relative',backgroundColor: '#F2F4E6' }} elevation={0}>
                  {/* Your content */}
                  {/* Vertical Line */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: '50%',
                      borderLeft: { xs: 'none', sm: '2px solid black' },
                      transform: 'translateX(-50%)'
                      
                    }}
                  />
                </Paper>
              </Grid>
            );
          }

          // Third row - display two sentences
          else if (index >= 8 && index < 12) {
            const pair = sentencePairs[index - 8]; // Adjust index to match sentencePairs array

            return (
                <Grid item xs={3} key={index}>
                <Paper sx={{ 
                  //border: '2px dashed black', 
                  padding: 1, 
                  height: '100px',
                  width: '100%',
                  backgroundColor: '#F2F4E6',
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center', // This will center the text vertically
                  alignItems: 'center', // This will center the text horizontally
                  position: 'relative' }} elevation={0}>
                  <Typography variant="subtitle1" component="p" sx={{ 
                    fontSize: { xs: '0.75rem', sm: '1rem' },
                    fontWeight: {
                      xs: 'bold', // Bold font weight on xs screens
                      sm: 'normal', // Normal font weight on sm screens and above
                    },
                    width: '100%', // Ensure text takes full width if needed
                    textAlign: 'center' // Center text horizontally if desired
                    }}>
                    {pair.first}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {pair.second}
                  </Typography>
                </Paper>
              </Grid>
            );
          }

          // Fourth row - leave as is or customize as needed
        })}
      </Grid>
    </Box>
  );
};

export default JourneyGrid;
