import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import imageUrl from './33.png';
import imageUrl2 from './1in4.png';

const MHInfo = () => {
  const { t, i18n } = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  // Replace with your actual image URL
  

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' }, // Column layout for xs screens
      bgcolor: '#F2F4E6',
      height: 'auto',
      width: '100%' // Full width to contain title on xs screens
    }}>
      <Box sx={{
        width: { sm: '25%', xs: '100%' }, // Full width on xs screens
        textAlign: 'left',
        fontSize: { sm: '42px', xs: '24px' }, // Smaller font size on xs screens
        p: 2,
        order: { xs: -1, sm: 0 } // Make the title first in order on xs screens
      }}>
        <Typography variant="h4" component="h1">
          {t('MHInfo.title')}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              bgcolor: '#A1E1E0',
              borderRadius: '10px',
              //borderStyle: 'dashed',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Text at top, image at bottom
              height: '100%',
              p: 2
            }}>
              <Typography variant="body1">
                {/* Replace with your actual text */}
                | {t('MHInfo.card1title')}
              </Typography>
              <Box
                component="img"
                src={imageUrl}
                alt="stat1"
                sx={{
                  width: 'auto', // Keep the image width proportional to its height
                  height: 'auto', // Set height to auto to maintain aspect ratio
                  maxWidth: '150px', // Set a maximum width for the image
                  maxHeight: '150px', // Set a maximum height for the image
                  borderRadius: '4px', // Optional: if you want to have rounded corners for the image
                  objectFit: 'contain', // This will make sure the image is scaled properly
                  mx: 'auto', // Centers the image horizontally in the container
                  my: 2, // Adds margin at the top and bottom of the image for spacing
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{
              bgcolor: '#FACEF3',
              borderRadius: '10px',
              //borderStyle: 'dashed',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Text at top, image at bottom
              height: '100%',
              p: 2
            }}>
              <Typography variant="body1">
                {/* Replace with your actual text */}
                {t('MHInfo.card2title')}
              </Typography>
              <Box
                component="img"
                src={imageUrl2}
                alt="stat1"
                sx={{
                  width: 'auto', // Keep the image width proportional to its height
                  height: 'auto', // Set height to auto to maintain aspect ratio
                  maxWidth: '150px', // Set a maximum width for the image
                  maxHeight: '150px', // Set a maximum height for the image
                  borderRadius: '4px', // Optional: if you want to have rounded corners for the image
                  objectFit: 'contain', // This will make sure the image is scaled properly
                  mx: 'auto', // Centers the image horizontally in the container
                  my: 2, // Adds margin at the top and bottom of the image for spacing
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
  <Box
    sx={{
      bgcolor: '#FDE4BE',
      borderRadius: '10px',
      //borderStyle: 'solid',
      p: 2,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center', // Centers vertically
      justifyContent: 'space-between' // Gives space between the two halves
    }}
  >
    {/* Left half for the title */}
    <Box sx={{ width: '50%' }}>
      <Typography variant="h6" component="h2">
        {t('MHInfo.card3title')}
      </Typography>
    </Box>
    
    {/* Right half for the centered text */}
    <Box
      sx={{
        width: '50%',
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center' // Centers vertically
      }}
    >
      <Typography variant="body1" sx={{ fontSize: 43 }}>
        {/* Replace with your actual centered text */}
        {t('MHInfo.card3stat')}
      </Typography>
    </Box>
  </Box>
</Grid>

        </Grid>
      </Box>
    </Box>
  );
};

export default MHInfo;
