import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import React, { useState } from 'react';
import TherapistLoginForm from "../../TherapistApps/LogIn/TherapistLogin";
import AllNavBar from "../Navbar";
import PatientLoginForm from "../PatientLogin/PatientLogin";
import FilterButton from "./FilterBox";
import TherapistCards from "./TherapistCards";




function SearchPage() {
    const [selectedFilters, setSelectedFilters] = useState({
      filter1: '',
      filter2: '',
      filter3: '',
    });
    const [TherapistList, setTherapistList] = useState([]); // Assuming you fetch the people data
  
    const handleSelectFilter = (filters) => {
      setSelectedFilters(filters);
    };
  
    
  
  
    return (
      <div className="App">
     <FilterButton
        selectedFilters={selectedFilters}
        onSelectFilter={handleSelectFilter}
      />
      <TherapistCards selectedFilters={selectedFilters} TherapistList={TherapistList} />
      </div>
    );
  }
  
  export default SearchPage;
  