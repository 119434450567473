import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box } from '@mui/material';
import { hostConfig } from "../../config.js";
import axios from 'axios';
//import AccountEdit from "./AccountInfoEdit.js";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import TherapistAccountEdit from "./TherapistInfoEdit.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const TherapistAccountInformation = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [therapistInfo, setTherapistInfo] = React.useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function fetchTherapistInfo() {
        try {
          const response = await axios.get(`${hostConfig.API_BASE}/gettherapistuuid`);
          setTherapistInfo(response.data[0]);
        } catch (error) {
          console.error('Error fetching therapist information:', error);
        }
    }
  
    fetchTherapistInfo();
  }, []);

  const showWarning = !therapistInfo.hourly_rate || !therapistInfo.introduction;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('therapistdashboard.accountinformation.title')}
      </Typography>

      {showWarning && (
        <Typography color="error" align="center">
          {t('therapistdashboard.accountinformation.warning')}
        </Typography>
      )}

      <Box>
        <p>{t('therapistdashboard.accountinformation.name')} {therapistInfo.first_name}</p>
        <p>{t('therapistdashboard.accountinformation.lastname')} {therapistInfo.last_name}</p>
        <p>{t('therapistdashboard.accountinformation.email')} {therapistInfo.email}</p>
        <p>{t('therapistdashboard.accountinformation.price')} {therapistInfo.hourly_rate}</p>
        <p>{t('therapistdashboard.accountinformation.description')} {therapistInfo.introduction}</p>
        <p>{t('therapistdashboard.accountinformation.practice')} {therapistInfo.practice}</p>
        
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleOpen}
        >
          {t('therapistdashboard.accountinformation.editbutton')}
        </Button>

        <BootstrapDialog onClose={handleClose} open={open}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              <TherapistAccountEdit therapistInfo={therapistInfo} />
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </Box>
    </Container>
  );
};

export default TherapistAccountInformation;
