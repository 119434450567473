import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { useTranslation } from "react-i18next";


const TherapistNotes = ({ isInCall, channel }) => {
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const intervalRef = useRef(); // To store the interval ID
    const { t,i18n } = useTranslation();

    useEffect(() => {
        const socketUrl = 'https://ascre-3e1eaeadf666.herokuapp.com/';
        const newSocket = io.connect(socketUrl, {
            query: { channel }
        });

        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log('Connected to the socket server');
            // Send channel information to the server
            newSocket.emit('joinChannel', { channel });
            console.log('Sent channel to the server:', channel);

            // Set up interval for sending messages every 5 minutes
            intervalRef.current = setInterval(() => {
                if (message) {
                    const messageData = { messageText: message, channel };
                    newSocket.emit('message', messageData);
                    console.log('Sending periodic message:', messageData);
                }
            }, 300000); // 300000 milliseconds = 5 minutes
        });

        newSocket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        return () => {
            // Emit an event when the user leaves the channel
            if (newSocket) {
                newSocket.emit('leaveChannel', { channel });
                console.log('Left channel:', channel);

                // Disconnect the socket
                newSocket.disconnect();
                console.log('Socket disconnected');
            }

            // Clear interval if set
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [channel, message]);

    return (
        <div>
            <textarea
                
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder= {t('therapistvideo.notes.placeholder')}
            />
        </div>
    );
};

export default TherapistNotes;
