import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Rating, Box, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Array of fake reviews
const fakeReviews = [
  {
    name: "Jane Doe",
    date: "November 20, 2023",
    rating: 4,
    comment: "The service was fantastic! Highly recommend.",
    avatarUrl: "https://via.placeholder.com/150"
  },
  {
    name: "John Smith",
    date: "November 18, 2023",
    rating: 5,
    comment: "Absolutely wonderful experience, the staff was so friendly.",
    avatarUrl: "https://via.placeholder.com/150"
  },
  // ... more reviews
];

const ReviewCard = ({ review, onPrev, onNext }) => {
  return (
    <Card sx={{ width: 500, height: 300, display: 'flex', position: 'relative' }}>
      <IconButton onClick={onPrev} sx={{ position: 'absolute', left: 0, top: '50%', zIndex: 1, transform: 'translateY(-50%)' }}>
        <ArrowBackIcon />
      </IconButton>

      <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar src={review.avatarUrl} sx={{ bgcolor: blue[500], mr: 2 }} />
          <Box>
            <Typography variant="h6">{review.name}</Typography>
            <Typography color="textSecondary">{review.date}</Typography>
          </Box>
        </Box>
        <Rating name="read-only" value={review.rating} readOnly />
        <Box sx={{ overflowY: 'auto', mt: 2, flexGrow: 1 }}>
          <Typography variant="body2" component="p">
            {review.comment}
          </Typography>
        </Box>
      </CardContent>

      <IconButton onClick={onNext} sx={{ position: 'absolute', right: 0, top: '50%', zIndex: 1, transform: 'translateY(-50%)' }}>
        <ArrowForwardIcon />
      </IconButton>
    </Card>
  );
};

const ReviewCarousel = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const handlePrev = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : fakeReviews.length - 1
    );
  };

  const handleNext = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex < fakeReviews.length - 1 ? prevIndex + 1 : 0
    );
  };

  const currentReview = fakeReviews[currentReviewIndex];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ReviewCard review={currentReview} onPrev={handlePrev} onNext={handleNext} />
    </Box>
  );
};

export default ReviewCarousel;
