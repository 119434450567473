import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Container, Box, Button, Typography, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { hostConfig } from '../../config';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_PROD_KEY);
console.log("Stripe Key:", process.env.REACT_APP_PUBLISHABLE_PROD_KEY);

const StyledCardElement = styled(CardElement)({
  marginTop: '16px',
  padding: '16px',
  border: '1px solid #ccc',
  borderRadius: '4px',
});

const CustomerForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setMessage('');

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email,
          name,
        },
      });

      if (error) {
        console.error(error);
        setMessage(error.message);
        setLoading(false);
        return;
      }

      const { data } = await axios.post(`${hostConfig.API_BASE}/savepaymentmethod`, {
        email,
        name,
        payment_method: paymentMethod.id,
      });

      console.log('Customer created or updated and payment method saved:', data);
      setMessage('Payment method saved successfully!');

    } catch (error) {
      console.error('Error saving customer and payment method:', error);
      setMessage('Error saving payment method. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Customer Information
        </Typography>
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <StyledCardElement />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={!stripe || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Save Payment Method'}
        </Button>
        {message && (
          <Typography color={message.includes('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

const SaveCCInfo = () => {
  return (
    <Elements stripe={stripePromise}>
      <CustomerForm />
    </Elements>
  );
};

export default SaveCCInfo;