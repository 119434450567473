import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const BookingComplete = () => {
  const { t, i18n } = useTranslation();
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/patientdashboard');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(i18n.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const bookingDate = searchparams.get("booking_date");
  const formattedDate = bookingDate ? formatDate(bookingDate) : '';

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        </Typography>
        <Typography variant="h5" component="div">
          {t('searchpage.bookingcompletepage.bookingcomplete')} {searchparams.get("first_name")}!
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        </Typography>
        <Typography variant="body2">
          {t('searchpage.bookingcompletepage.bookingdate')} {formattedDate}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={navigateToDashboard}>{t('searchpage.bookingcompletepage.return')}</Button>
      </CardActions>
    </Card>
  );
}

export default BookingComplete;