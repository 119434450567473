import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box, Select, MenuItem, InputAdornment, FormHelperText } from '@mui/material';
import { hostConfig } from "../../config";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const TherapistAccountEdit = ({ therapistInfo }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [accountsData, setAccountsData] = useState([{
    ...therapistInfo,
    currency: '',
  }]);
  const [currencyError, setCurrencyError] = useState(false);

  const currencies = {
    AUD: { symbol: '$', multiplier: 100 },
    SGD: { symbol: 'S$', multiplier: 100 },
    USD: { symbol: '$', multiplier: 100 },
    JPY: { symbol: '¥', multiplier: 1 },
  };

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    setAccountsData((prevData) =>
      prevData.map((account) => ({ ...account, [field]: value }))
    );
  };

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setAccountsData((prevData) =>
      prevData.map((account) => ({ ...account, currency: newCurrency }))
    );
    setCurrencyError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const account = accountsData[0];

    if (!account.currency) {
      setCurrencyError(true);
      return;
    }

    const priceMultiplier = currencies[account.currency].multiplier;
    const formattedPrice = Math.round(parseFloat(account.hourly_rate) * priceMultiplier);

    try {
      const response = await axios.post(`${hostConfig.API_BASE}/therapistaccountedit`, {
        therapist_first_name: account.first_name,
        therapist_last_name: account.last_name,
        therapist_email: account.email,
        therapist_phone_number: account.phoneNumber,
        therapist_uuid: account.uuid,
        price: formattedPrice,
        currency: account.currency,
        description: account.introduction,
        practice: account.practice,
      });

      if (response && response.data) {
        console.log('Information successfully updated', response.data);
        navigate(0);
      } else {
        console.log('Update failed: No response data');
      }
    } catch (error) {
      console.log('Error occurred while updating:', error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('therapistdashboard.accountedit.title')}
      </Typography>
      {accountsData.map((account) => (
        <Box key={account.id} component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <TextField label={t('therapistdashboard.accountedit.firstname')} value={account.first_name} onChange={(e) => handleInputChange(e, 'first_name')} />
          <TextField label={t('therapistdashboard.accountedit.lastname')} value={account.last_name} onChange={(e) => handleInputChange(e, 'last_name')} />
          <TextField label={t('therapistdashboard.accountedit.email')} value={account.email} onChange={(e) => handleInputChange(e, 'email')} />
          <TextField label={t('therapistdashboard.accountedit.phonenumber')} value={account.phoneNumber} onChange={(e) => handleInputChange(e, 'phoneNumber')} />
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <TextField
              label={t('therapistdashboard.accountedit.price')}
              value={account.hourly_rate}
              onChange={(e) => handleInputChange(e, 'hourly_rate')}
              InputProps={{
                startAdornment: <InputAdornment position="start">{account.currency ? currencies[account.currency].symbol : ''}</InputAdornment>,
              }}
              sx={{ flexGrow: 1 }}
            />
            <Select
              value={account.currency}
              onChange={handleCurrencyChange}
              sx={{ width: '100px' }}
              error={currencyError}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {t('therapistdashboard.accountedit.selectcurrency')}
              </MenuItem>
              {Object.keys(currencies).map((curr) => (
                <MenuItem key={curr} value={curr}>{curr}</MenuItem>
              ))}
            </Select>
          </Box>
          {currencyError && <FormHelperText error>{t('therapistdashboard.accountedit.currencyerror')}</FormHelperText>}
          <TextField label={t('therapistdashboard.accountedit.description')} value={account.introduction} onChange={(e) => handleInputChange(e, 'introduction')} />
          <TextField label={t('therapistdashboard.accountedit.practice')} value={account.practice} onChange={(e) => handleInputChange(e, 'practice')} />
          <Button variant="contained" color="primary" type="submit">
            {t('therapistdashboard.accountedit.editbutton')}
          </Button>
        </Box>
      ))}
    </Container>
  );
};

export default TherapistAccountEdit;