import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from "axios";
import { IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff, Call, Schedule, PlayArrow, Pause, TextSnippet } from '@mui/icons-material';
import ScheduleSetterButton from './Scheduler/SchedulerButton';
import CalendarSender from './CalendarRequest/RequestSender';
import { hostConfig } from '../../config';
import ScheduleSetterButtonV2 from './Scheduler/SchedulerButtonV2';

const appId = "5c0757f9509447ec80344d6b2557262d";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const VideoChatPagetest = ({ filler }) => {
  const location = useLocation();
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [cameraState, setCameraState] = useState('initializing');
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [calling, setCalling] = useState(false);

  const { session_uuid } = useParams();

  const { meeting_token, channel, uid } = location.state || {};

  console.log('Session UUID:', session_uuid);        // From URL params
  console.log('Meeting Token:', meeting_token);      // From Link state
  console.log('Channel:', channel);                  // From Link state
  console.log('Full location state:', location.state); // Log full state object

  // Timer states
  const initialMinutes = 60;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  console.log(session_uuid, meeting_token, "meeting token and session uuid in videochatpage");

  useEffect(() => {
    const init = async () => {
      try {
        const devices = await AgoraRTC.getCameras();
        setVideoDevices(devices);
        if (devices.length > 0) {
          setSelectedDeviceId(devices[0].deviceId);
        }
      } catch (error) {
        console.error("Error getting camera devices:", error);
        setCameraState('error');
        setError('Failed to get camera devices. Please check your permissions.');
      }
    };

    init();
  }, []);

  useEffect(() => {
    const initializeAgora = async () => {
      if (!appId || !session_uuid || !meeting_token) {
        setError('Agora App ID, session UUID, or meeting token is missing. Please check your configuration.');
        return;
      }

      try {
        // Initialize event handlers before joining
        client.on('user-published', handleUserPublished);
        client.on('user-unpublished', handleUserUnpublished);

        // Join the channel
        await client.join(appId, session_uuid, meeting_token, null);
        console.log('Successfully joined the channel');

        // Create tracks
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        const videoTrack = await AgoraRTC.createCameraVideoTrack({
          deviceId: selectedDeviceId,
          encoderConfig: {
            width: 640,
            height: 480,
            frameRate: 30,
            bitrateMin: 400,
            bitrateMax: 1000,
          }
        });

        // Set local tracks
        setLocalAudioTrack(audioTrack);
        setLocalVideoTrack(videoTrack);

        // Play local video
        if (localVideoRef.current) {
          videoTrack.play(localVideoRef.current);
        }

        // Publish tracks
        await client.publish([audioTrack, videoTrack]);
        console.log('Successfully published tracks');

        setJoinState(true);
        setCameraState('ready');

      } catch (error) {
        console.error("Error in initializeAgora:", error);
        setCameraState('error');
        setError(`Failed to initialize: ${error.message}`);
      }
    };

    if (selectedDeviceId && session_uuid && meeting_token) {
      initializeAgora();
    }

    // Cleanup function
    return () => {
      if (localAudioTrack) {
        localAudioTrack.stop();
        localAudioTrack.close();
      }
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
      }
      client.removeAllListeners();
      client.leave().catch(console.error);
    };
  }, [selectedDeviceId, session_uuid, meeting_token]
  );

  useEffect(() => {
    let interval;
    if (isActive && (minutes > 0 || seconds > 0)) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds => seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes => minutes - 1);
          setSeconds(59);
        }
      }, 1000);
    } else if (!isActive || (minutes === 0 && seconds === 0)) {
      setIsActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, minutes, seconds]);

  const handleUserPublished = async (user, mediaType) => {
    try {
        console.log(`User ${user.uid} published ${mediaType} track`);
        await client.subscribe(user, mediaType);
        console.log(`Successfully subscribed to ${mediaType}`);

        if (mediaType === 'video') {
            setRemoteUsers(prevUsers => {
                if (!prevUsers.find(u => u.uid === user.uid)) {
                    return [...prevUsers, user];
                }
                return prevUsers;
            });

            if (remoteVideoRef.current) {
                user.videoTrack.play(remoteVideoRef.current);
                console.log('Playing remote video');
            }
        }

        if (mediaType === 'audio') {
            user.audioTrack.play();
            console.log('Playing remote audio');
        }
    } catch (error) {
        console.error('Error in handleUserPublished:', error);
    }
};

  const handleUserUnpublished = (user) => {
    setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
  };

  const toggleAudio = () => {
    if (localAudioTrack) {
      localAudioTrack.setEnabled(!isAudioMuted);
      setIsAudioMuted(!isAudioMuted);
    }
  };

  const toggleVideo = async () => {
    if (localVideoTrack) {
      try {
        await localVideoTrack.setEnabled(!isVideoMuted);
        setIsVideoMuted(!isVideoMuted);

        if (!isVideoMuted && localVideoRef.current) {
          localVideoTrack.play(localVideoRef.current);
        }
      } catch (error) {
        console.error('Error toggling video:', error);
      }
    }
  };

  const handleHangUp = async () => {
    setCalling(prevCalling => !prevCalling);

    if (calling) {
      try {
        const response = await axios.post(`${hostConfig.API_BASE}/therapistleaveroom`,
          { session_uuid: session_uuid });
        console.log(response.data);
      } catch (error) {
        console.error('Hang up error:', error);
      }
    }
  };

  const toggleTimer = () => setIsActive(!isActive);

  const formatTime = (minutes, seconds) => {
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const toggleNotes = () => {
    setIsNotesVisible(!isNotesVisible);
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {error && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center', zIndex: 1000 }}>
          {error}
        </div>
      )}

      {/* Remote Video (Full Screen) */}
      <div ref={remoteVideoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#000' }} />

      {/* Local Video (Bottom Left) */}
      <div style={{ position: 'absolute', bottom: 80, left: 20, width: '200px', height: '150px', backgroundColor: '#ddd', overflow: 'hidden' }}>
        {cameraState === 'ready' ? (
          <div ref={localVideoRef} style={{ width: '100%', height: '100%', display: isVideoMuted ? 'none' : 'block' }} />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '10px', textAlign: 'center' }}>
            {cameraState === 'initializing' ? 'Initializing camera...' : 'Camera error. Please check permissions.'}
          </div>
        )}
        {isVideoMuted && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#333', color: 'white' }}>
            Camera Off
          </div>
        )}
      </div>

      {/* Bottom Control Bar */}
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
      }}>
        <IconButton onClick={toggleAudio} color="primary">
          {isAudioMuted ? <MicOff /> : <Mic />}
        </IconButton>
        <IconButton onClick={toggleVideo} color="primary">
          {isVideoMuted ? <VideocamOff /> : <Videocam />}
        </IconButton>
        <IconButton
          onClick={handleHangUp}
          color={calling ? "secondary" : "primary"}
        >
          <Call />
        </IconButton>
        <IconButton onClick={toggleTimer} color="primary">
          {isActive ? <Pause /> : <PlayArrow />}
        </IconButton>
        <span style={{ color: 'white', marginRight: '10px' }}>{formatTime(minutes, seconds)}</span>
        <IconButton color="primary">
          <ScheduleSetterButtonV2 />
        </IconButton>
        <IconButton color="primary">
          <CalendarSender />
        </IconButton>
        <IconButton onClick={toggleNotes} color="primary">
          <TextSnippet />
        </IconButton>
      </div>

      {/* Notes Section (Right Side) */}
      <div style={{
        position: 'absolute',
        top: 0,
        right: isNotesVisible ? 0 : '-300px',
        width: '300px',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.9)',
        transition: 'right 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <h3>Notes</h3>
        <textarea
          value={notes}
          onChange={handleNotesChange}
          style={{ flex: 1, width: '100%', padding: '10px', boxSizing: 'border-box', resize: 'none' }}
          placeholder="Type your notes here..."
        />
      </div>
    </div>
  );
};

export default VideoChatPagetest;