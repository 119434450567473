

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HeroSection from './HeroSection/HeroSection';
import MHInfo from './MentalHealthInfo/MHInfo';
import SymptomSection from './Symptoms/SymptomSection';
import TheJourney from './TheJourneySection/TheJourneySection';
import HowToUseSection from './HowToUse/HowToUse';
import FindTherapistSection from './FindTherapist/FindTherapist';
import CustomerVoice from './CustomerVoice/CustomerVoice';
import TherapistRecruitSection from './TherapistRecruit/TherapistRecruit';
import Footer from './Footer/Footer';






function FrontPage() {


  // 


  return (    <div className="App">
   <HeroSection />
   <MHInfo />
   <SymptomSection />
   <TheJourney />
   <HowToUseSection />
   <FindTherapistSection />
   <CustomerVoice />
   <TherapistRecruitSection />
   <Footer />
    
    

    </div>)


  ;
}

export default FrontPage;
