import React, { useEffect, useState } from "react";
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { hostConfig } from "../../config.js";
import axios from 'axios';
import { useSearchParams,useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";




const AccountEdit = ({patientInfo}) => {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();
  const arrayData = [patientInfo];
  const [accountsData, setAccountsData] = useState(arrayData);
  console.log(patientInfo.first_name, "console log patientinfoname object check");
  console.log(patientInfo.last_name, "console log patientinfolastname object check");
  console.log(arrayData, "console log patientinfolastname object check");

  const handleInputChange = (event, id, field) => {
    const value = event.target.value;

    setAccountsData((prevData) =>
      prevData.map((account) => ( { ...account, [field]: value } ))
    );


  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here, you can implement logic to save the updated account information to your backend or do any other necessary actions.
    console.log('Updated account data:', accountsData);
    console.log('Updated account data: array', accountsData[0].first_name);
    const patient_first_name = accountsData[0].first_name;
    const patient_last_name = accountsData[0].last_name;
    const patient_email = accountsData[0].email;
    const patient_uuid = accountsData[0].uuid;
    const patient_phone_number = accountsData[0].phoneNumber;
    try {
      

      const response = await axios.post(`${hostConfig.API_BASE}/patientaccountedit`, 
       { patient_first_name,patient_last_name,patient_email,patient_phone_number,patient_uuid }
      );

      if (response && response.data) {
        console.log('Information successfully updated', response.data);
        navigate(0);
      } else {
        console.log('Registration failed: No response data');
      }
    } catch (error) {
      if (error.response) {
        console.log('Error occurred while registering:', error.response.data);
      } else if (error.request) {
        console.log('Error occurred: No response received');
      } else {
        console.log('Error occurred:', error.message,);
      }
    }


  };
  

  return (
    <Container maxWidth="sm" >

      <Typography variant="h4" align="center" gutterBottom>
      {t('patientdashboard.accountedit.title')}
        
      </Typography>
      {accountsData.map((account) => (
        <Box key={account.id} component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <TextField label= {t('patientdashboard.accountedit.firstname')} value={account.first_name} onChange={(e) => handleInputChange(e, account.id, 'first_name')} />
          <TextField label= {t('patientdashboard.accountedit.lastname')} value={account.last_name} onChange={(e) => handleInputChange(e, account.id, 'last_name')} />
          <TextField label= {t('patientdashboard.accountedit.email')} value={account.email} onChange={(e) => handleInputChange(e, account.id, 'email')} />
          <TextField label= {t('patientdashboard.accountedit.phonenumber')} value={account.phoneNumber} onChange={(e) => handleInputChange(e, account.id, 'phoneNumber')} />
          <Button variant="contained" color="primary" type="submit" >
          {t('patientdashboard.accountedit.edit')}
          </Button>
          
        </Box>
      ))}
    </Container>
  );
};

export default AccountEdit;
