import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';

const LineButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  textTransform: 'none',
  backgroundColor: '#06C755',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#05A847',
  },
}));

const LineLoginButton = ({ userType }) => {
  const { t } = useTranslation();

  const handleLineLogin = () => {
    const clientId = 2000756017;
    const redirectUri = `https://www.ascreforme.com/callback`;
    const state = `${userType}_${uuidv4()}`; 
    const scope = 'profile openid email';

    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=${scope}`;

    window.location.href = lineAuthUrl;
  };

  return (
    <LineButton 
      onClick={handleLineLogin} 
      startIcon={<img src="/line-icon.png" alt="Line" width="20" height="20" />}
    >
      {t(`${userType}login.linelogin`)}
    </LineButton>
  );
};

export default LineLoginButton;