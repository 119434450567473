import React from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { hostConfig } from './config';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    // Clear everything in local storage
    localStorage.clear();
    navigate('/patientlogin'); 

    // Send a request to the backend to clear the session
    try {
      await axios.post(`${hostConfig.API_BASE}/logout`);
      // Redirect to login page or home page as needed
      window.location.href = '/patientlogin';
    } catch (error) {
      console.error('Logout failed', error);
      // Handle logout failure as needed
    }
  };

  return (
    <Button variant="contained" onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;
