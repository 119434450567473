import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from "react-i18next";







const TermsofUse = () => {
  const { t,i18n } = useTranslation();

  const termsofuse = [
    {number:t("termsofuse.firstnumber"),rule:t("termsofuse.firstrule"),text:t("termsofuse.firsttext")}
    ,{number:t("termsofuse.firstrule"),rule:t("termsofuse.firsttext"),text:t("termsofuse.secondnumber")}
    ,{number:t("termsofuse.firsttext"),rule:t("termsofuse.secondnumber"),text:t("termsofuse.secondrule")}
    ,{number:t("termsofuse.secondnumber"),rule:t("termsofuse.secondrule"),text:t("termsofuse.secondtext")}
    ,{number:t("termsofuse.secondrule"),rule:t("termsofuse.secondtext"),text:t("termsofuse.thirdnumber")}
    ,{number:t("termsofuse.secondtext"),rule:t("termsofuse.thirdnumber"),text:t("termsofuse.thirdrule")}
    ,{number:t("termsofuse.thirdnumber"),rule:t("termsofuse.thirdrule"),text:t("termsofuse.thirdtext")}
    ,{number:t("termsofuse.thirdrule"),rule:t("termsofuse.thirdtext"),text:t("termsofuse.fourthnumber")}
    
    ]

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              {t('termsofuse.title')}
            </Typography>
    
            <List>
              {termsofuse.map((term, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={`${term.number} (${term.rule})`}
                    secondary={term.text}
                    secondaryTypographyProps={{ component: 'div' }} // To maintain proper formatting if there are line breaks
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Container>
      );
};

export default TermsofUse;
