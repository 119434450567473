import React from "react";
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import "./HeroSection.css"; 
import { useTranslation } from 'react-i18next';



const HeroSection = () => {

  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="HeroSection">
      <div className="HeroInnerSection">
        <div className="HeroText">
        {t('heropage.herotext1')}<br></br>{t('heropage.herotext2')}
        </div>
        <div className="HeroCTA">
        <Link to = "/searchtherapist">
        <Button 
            sx={{ 
                backgroundColor: "#0A6621", '&:hover': { backgroundColor: "#026e3a" },
                color:"white"
            }} // Add your desired hover color

        >
            {t('heropage.button')}
          </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
