import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
//import FormHelperText from '@mui/material/FormHelperText';
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";

//import {View, TouchableOpacity, Text, StyleSheet} from 'react-native';

//started mui because react-bootstrap didn't work for unknown reason lmfao.  - Kota
//Prioritizing it working rather than doing the same thing - which is awful but whatever, i'm not an engineer - Kota


const FilterButton = ({ selectedFilters, onSelectFilter }) => {
  const { t,i18n } = useTranslation();

    const handleFilterChange = (event) => {
      const filterName = event.target.name;
      const filterValue = event.target.value;
  
      const updatedFilters = { ...selectedFilters, [filterName]: filterValue };
      onSelectFilter(updatedFilters);
    };
  
    return (
      
      
      <div style={{ 
        width: "100%",
        backgroundColor: "#D3D3D3",
        display: "flex",
        justifyContent: "center",
        marginTop: "2em",
        alignItems: "center"
      }}>
        
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">{t('searchpage.filterbox.rating')}</InputLabel>
          <Select
            name="filter3"
            value={selectedFilters.filter3}
            onChange={handleFilterChange}
            label="rating"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="⭐︎">⭐︎</MenuItem>
            <MenuItem value="⭐︎⭐︎">⭐︎⭐︎</MenuItem>
            <MenuItem value="⭐︎⭐︎⭐︎">⭐︎⭐︎⭐︎</MenuItem>
            <MenuItem value="⭐︎⭐︎⭐︎⭐︎">⭐︎⭐︎⭐︎⭐︎</MenuItem>
            <MenuItem value="⭐︎⭐︎⭐︎⭐︎⭐︎">⭐︎⭐︎⭐︎⭐︎⭐︎</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
          {t('searchpage.filterbox.practice')}
          </InputLabel>
          <Select
            name="filter1"
            value={selectedFilters.filter1}
            onChange={handleFilterChange}
            label="Practice"
          >
            <MenuItem value="">
              <em>{t('searchpage.filterbox.practice')}</em>
            </MenuItem>
            <MenuItem value="Cognitive">Cognitive</MenuItem>
            <MenuItem value="Cognitive-Behavioral">Cognitive-Behavioral</MenuItem>
            <MenuItem value="Freudian">Freudian</MenuItem>
            <MenuItem value="Behavioral">Behvaioral</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
          {t('searchpage.filterbox.price_range')}
          </InputLabel>
          <Select
            name="filter2"
            value={selectedFilters.filter2}
            onChange={handleFilterChange}
            label="price_range"
          >
            <MenuItem value="">
              <em>{t('searchpage.filterbox.price_range')}</em>
            </MenuItem>
            <MenuItem value="$">{t('searchpage.filterbox.price_value1')}</MenuItem>
            <MenuItem value="$$">{t('searchpage.filterbox.price_value2')}</MenuItem>
            <MenuItem value="$$$">{t('searchpage.filterbox.price_value3')}</MenuItem>
            <MenuItem value="$$$$">{t('searchpage.filterbox.price_value4')}</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };
  
  export default FilterButton;
  