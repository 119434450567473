import React from "react";
import TherapistCards from "./FindTherapistCards";
import './FindTherapist.css'
import { useTranslation } from 'react-i18next';

const FindTherapistSection = () => {
    const { t, i18n } = useTranslation();

    const switchLanguage = (language) => {
      i18n.changeLanguage(language);
    };
    return(
        <div className="FindTherapistSection"> {/* Correct the class name here */}
            <div className="FindTherapistTitle">
            |{t('FindCounselor.title')}
            </div>
            <div className="FindTherapistLists"> {/* Correct the class name here */}
                <TherapistCards />
            </div>
        </div>
    )
}

export default FindTherapistSection;
