import React from "react";
import './HowToUse.css'
import StackedCards from "./HowToUseStack";
import { useTranslation } from 'react-i18next';



const HowToUseSection = () => {

    const { t,i18n} = useTranslation();

    const switchLanguage = (language) => {
      i18n.changeLanguage(language);
    };
    return(
        <div className = "HowToUseSection">
            <div className = "HowToUseTitle">
            |{t('HowtoUse.HowtoUseTitle')}
            </div>
            <div className = "HowToUseList">
                <StackedCards />
            </div>

        </div>
    )
}

export default HowToUseSection;