import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import InVideoCalendarModal from './CalendarModal';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

const firebaseConfig = {
    apiKey: "AIzaSyAfrKlyWtplvug2Drol81m5pUTXwDdpEDw",
    authDomain: "ascre-65d72.firebaseapp.com",
    databaseURL: "https://ascre-65d72-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ascre-65d72",
    storageBucket: "ascre-65d72.appspot.com",
    messagingSenderId: "243468257698",
    appId: "1:243468257698:web:2f9faeeff618bce38b4afb",
    measurementId: "G-H5YNGZ8LKT"
};

// Initialize Firebase once
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Styled components for better positioning
const ReceiverContainer = styled('div')({
    position: 'fixed',
    inset: 0,
    zIndex: 1000,
    pointerEvents: 'none',
    '& > *': {
        pointerEvents: 'auto'
    }
});

const Receiver = () => {
    const [modalState, setModalState] = useState({
        isVisible: false,
        therapistUuid: null,
        message: ''
    });
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'info' // 'error', 'warning', 'info', 'success'
    });

    useEffect(() => {
        let unsubscribe = null;
        
        try {
            const session_uuid = localStorage.getItem('session_uuid');
            
            if (!session_uuid) {
                console.log('No session UUID found in localStorage');
                showNotification('No active session found', 'warning');
                return;
            }

            const messageRef = ref(database, `session/${session_uuid}`);
            
            unsubscribe = onValue(messageRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    
                    if (data.therapist_uuid && data.message) {
                        setModalState({
                            isVisible: true,
                            therapistUuid: data.therapist_uuid,
                            message: data.message
                        });
                        // Optional: Show notification when request is received
                        showNotification('New calendar request received', 'info');
                    }
                } else {
                    setModalState({
                        isVisible: false,
                        therapistUuid: null,
                        message: ''
                    });
                }
            }, (error) => {
                console.error('Firebase onValue error:', error);
                showNotification('Failed to connect to notification service', 'error');
            });

        } catch (err) {
            console.error('Error setting up Firebase listener:', err);
            showNotification('Failed to initialize notification service', 'error');
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
            setModalState({
                isVisible: false,
                therapistUuid: null,
                message: ''
            });
            // Clear any remaining notifications
            setNotification(prev => ({ ...prev, open: false }));
        };
    }, []);

    const showNotification = (message, severity = 'info') => {
        setNotification({
            open: true,
            message,
            severity
        });
    };

    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification(prev => ({ ...prev, open: false }));
    };

    const handleModalClose = () => {
        setModalState(prev => ({
            ...prev,
            isVisible: false
        }));
        // Optional: Show notification when modal is closed
        showNotification('Calendar request closed', 'info');
    };

    return (
        <ReceiverContainer>
            {/* Notification Snackbar */}
            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={handleNotificationClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleNotificationClose}
                    severity={notification.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
            
            {/* Calendar Modal */}
            {modalState.isVisible && modalState.therapistUuid && (
                <div style={{ pointerEvents: 'auto' }}>
                    <InVideoCalendarModal 
                        therapistUuid={modalState.therapistUuid}
                        onClose={handleModalClose}
                        onError={(errorMessage) => {
                            showNotification(errorMessage, 'error');
                        }}
                        onSuccess={(successMessage) => {
                            showNotification(successMessage, 'success');
                        }}
                    />
                </div>
            )}
        </ReceiverContainer>
    );
};

export default Receiver;