import React, { Component } from "react";

class CloudinaryUploadWidgetImage extends Component {
  componentDidMount() {
    const cloudName = "hkqlkwtmu"; // Replace with your own cloud name
    const uploadPreset = "z4lendju"; // Replace with your own upload preset

    // Note: Removed comments for brevity. See the full list of possible parameters at:
    // https://cloudinary.com/documentation/upload_widget_reference
    const therapistUUID = localStorage.getItem('uuid'); // Corrected to use getItem method

    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudName,
        uploadPreset: uploadPreset,
        public_id: therapistUUID, // This is optional. Cloudinary can generate a random ID if not provided.
        showAdvancedOptions: true, // Add advanced options (public_id and tag)
        sources: ["local", "url", "google_drive"], // Restrict the upload sources
        folder: "TherapistImage", // Changed folder name to indicate images
        clientAllowedFormats: ["image"], // Now restricted to uploading image files only
        // Additional image-specific configurations can be added here as needed
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          document
            .getElementById("uploadedimage")
            .setAttribute("src", result.info.secure_url);
        }
      }
    );

    document.getElementById("upload_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );
  }

  render() {
    return (
      <button id="upload_widget" className="cloudinary-button">
        Upload
      </button>
    );
  }
}

export default CloudinaryUploadWidgetImage;
