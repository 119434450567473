import React, { useState, useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff, CallEnd } from '@mui/icons-material';
import Receiver from './CalendarRequestPopup/CalendarReceiver';
import { hostConfig } from '../../../config';
import './VideoChatPage.css';

const appId = "5c0757f9509447ec80344d6b2557262d";

const PatientVideoV2 = () => {
  const [client] = useState(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }));
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [start, setStart] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState({});
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [error, setError] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const localVideoRef = useRef(null);
  const remoteVideoRefs = useRef({});

  const token = location.state?.meeting_token;
  const channelName = location.state?.session_uuid;

  useEffect(() => {
    console.log('Remote Users State:', remoteUsers);
  }, [remoteUsers]);

  useEffect(() => {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-left", handleUserLeft);
  
    const joinChannel = async () => {
      if (!start && token && channelName) {
        try {
          console.log('Joining channel with:', { channelName, token });
          await client.join(appId, channelName, token, null);
          console.log('Successfully joined channel');
  
          const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          
          // Ensure tracks are enabled when created
          microphoneTrack.setEnabled(true);
          setLocalAudioTrack(microphoneTrack);
          setLocalVideoTrack(cameraTrack);
          setIsAudioMuted(false);  // Initialize as unmuted
  
          if (localVideoRef.current) {
            cameraTrack.play(localVideoRef.current);
          }
          
          await client.publish([microphoneTrack, cameraTrack]);
          console.log('Published local tracks');
  
          setStart(true);
        } catch (error) {
          console.error("Error joining Agora channel:", error);
          setError("Failed to join the video call. Please try again.");
        }
      }
    };
  
    joinChannel();
  
    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-left", handleUserLeft);
    };
  }, [client, token, channelName, start]);

  useEffect(() => {
    const cleanup = async () => {
      if (start) {
        await leaveChannel();
      }
    };

    // Handle page unload
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      cleanup();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      cleanup();
    };
  }, [start]);

  const handleUserPublished = async (user, mediaType) => {
    try {
      console.log('User Published:', user.uid, mediaType);
      await client.subscribe(user, mediaType);
      console.log('Subscribed to:', user.uid, mediaType);

      if (mediaType === 'video') {
        console.log('Setting up video for:', user.uid);
        setRemoteUsers((prevUsers) => ({
          ...prevUsers,
          [user.uid]: { ...prevUsers[user.uid], video: user.videoTrack },
        }));
        
        // Play the video immediately after setting state
        if (remoteVideoRefs.current[user.uid]) {
          user.videoTrack.play(remoteVideoRefs.current[user.uid]);
        }
      }

      if (mediaType === 'audio') {
        setRemoteUsers((prevUsers) => ({
          ...prevUsers,
          [user.uid]: { ...prevUsers[user.uid], audio: user.audioTrack },
        }));
        user.audioTrack.play();
      }
    } catch (error) {
      console.error('Error in handleUserPublished:', error);
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    console.log('User Unpublished:', user.uid, mediaType);
    setRemoteUsers((prevUsers) => {
      const updatedUser = { ...prevUsers[user.uid] };
      delete updatedUser[mediaType];
      return { ...prevUsers, [user.uid]: updatedUser };
    });
  };

  const handleUserLeft = (user) => {
    console.log('User Left:', user.uid);
    setRemoteUsers((prevUsers) => {
      const updatedUsers = { ...prevUsers };
      delete updatedUsers[user.uid];
      return updatedUsers;
    });
  };

  const toggleVideo = async () => {
    try {
      if (!isVideoMuted) {
        // Currently ON -> turning OFF
        if (localVideoTrack) {
          await client.unpublish(localVideoTrack);
          localVideoTrack.stop();
          localVideoTrack.close();
          setLocalVideoTrack(null);
          if (localVideoRef.current) {
            localVideoRef.current.innerHTML = '';
          }
        }
      } else {
        // Currently OFF -> turning ON
        if (localVideoTrack) {
          // Ensure old track is fully cleaned up
          await client.unpublish(localVideoTrack);
          localVideoTrack.stop();
          localVideoTrack.close();
        }
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack();
        setLocalVideoTrack(newVideoTrack);
        if (localVideoRef.current) {
          newVideoTrack.play(localVideoRef.current);
        }
        await client.publish([newVideoTrack]);
      }
      setIsVideoMuted(!isVideoMuted);
    } catch (error) {
      console.error('Error toggling video:', error);
    }
  };

  // Fixed toggle audio function with proper cleanup
  const toggleAudio = async () => {
    try {
      console.log('Toggling audio. Current state:', { isAudioMuted, localAudioTrack });
      
      if (localAudioTrack) {
        // Instead of unpublishing/republishing, just enable/disable the track
        localAudioTrack.setEnabled(!isAudioMuted);
        setIsAudioMuted(!isAudioMuted);
        console.log('Audio track toggled:', !isAudioMuted);
      } else {
        // If track doesn't exist, create a new one
        console.log('Creating new audio track');
        const newAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        setLocalAudioTrack(newAudioTrack);
        await client.publish([newAudioTrack]);
        setIsAudioMuted(false);
        console.log('New audio track created and published');
      }
    } catch (error) {
      console.error('Error toggling audio:', error);
      // If there's an error, try to recreate the track
      try {
        console.log('Attempting to recover audio track');
        if (localAudioTrack) {
          localAudioTrack.stop();
          localAudioTrack.close();
        }
        const newAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        setLocalAudioTrack(newAudioTrack);
        await client.publish([newAudioTrack]);
        setIsAudioMuted(false);
        console.log('Audio track recovered successfully');
      } catch (recoveryError) {
        console.error('Failed to recover audio track:', recoveryError);
      }
    }
  };

  const checkAudioTrackState = () => {
    if (localAudioTrack) {
      console.log('Audio Track State:', {
        enabled: localAudioTrack.isEnabled,
        muted: isAudioMuted,
        trackEnded: localAudioTrack.trackEnded
      });
    } else {
      console.log('No audio track available');
    }
  };

  // Enhanced leave channel function with guaranteed cleanup
  const leaveChannel = async () => {
    try {
      console.log('Starting channel leave process...');
      
      // 1. Unpublish all tracks first
      const tracksToUnpublish = [localAudioTrack, localVideoTrack].filter(Boolean);
      if (tracksToUnpublish.length > 0) {
        console.log('Unpublishing tracks...');
        await client.unpublish(tracksToUnpublish);
      }

      // 2. Stop and close tracks individually
      if (localAudioTrack) {
        console.log('Cleaning up audio track...');
        localAudioTrack.stop();
        localAudioTrack.close();
        setLocalAudioTrack(null);
      }

      if (localVideoTrack) {
        console.log('Cleaning up video track...');
        localVideoTrack.stop();
        localVideoTrack.close();
        setLocalVideoTrack(null);
      }

      // 3. Clear video elements
      if (localVideoRef.current) {
        console.log('Clearing local video element...');
        localVideoRef.current.innerHTML = '';
      }

      Object.values(remoteVideoRefs.current).forEach(ref => {
        if (ref) ref.innerHTML = '';
      });

      // 4. Leave the channel
      console.log('Leaving Agora channel...');
      await client.leave();

      // 5. Reset all states
      setStart(false);
      setRemoteUsers({});
      setIsVideoMuted(true);
      setIsAudioMuted(true);

      // 6. Notify backend
      const session_uuid = localStorage.getItem('session_uuid');
      if (session_uuid) {
        console.log('Notifying backend of leave...');
        await axios.post(`${hostConfig.API_BASE}/patientleaveroom`, {
          timestamp: new Date().toISOString(),
          session_uuid: session_uuid
        });
      }

      // 7. Clean up localStorage
      localStorage.removeItem('therapist_uuid');
      localStorage.removeItem('session_uuid');

      // 8. Navigate away
      navigate('/patientdashboard');

      console.log('Leave channel process completed successfully');
    } catch (error) {
      console.error('Error during leave channel:', error);
      // Force cleanup even if there's an error
      setLocalAudioTrack(null);
      setLocalVideoTrack(null);
      setStart(false);
      navigate('/patientdashboard');
    }
  };

  



  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {error && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '10px', 
          textAlign: 'center', 
          zIndex: 1000 
        }}>
          {error}
        </div>
      )}
      
      {/* Remote Video (Full Screen) */}
      <div className="patient-remote-users" style={{ 
        width: '100%', 
        height: '100%', 
        backgroundColor: '#000',
        position: 'relative'
      }}>
        {Object.entries(remoteUsers).map(([uid, user]) => (
          <div 
            key={uid} 
            style={{ 
              width: '100%', 
              height: '100%', 
              position: 'absolute',
              top: 0,
              left: 0
            }}
          >
            <div 
              ref={(el) => (remoteVideoRefs.current[uid] = el)} 
              style={{ 
                width: '100%', 
                height: '100%',
                objectFit: 'contain'
              }} 
            />
          </div>
        ))}
      </div>
      
      {/* Local Video (Bottom Left) */}
      <div 
        ref={localVideoRef} 
        style={{
          position: 'absolute', 
          bottom: 80, 
          left: 20, 
          width: '160px', 
          height: '120px', 
          backgroundColor: '#ddd',
          borderRadius: '15px',
          overflow: 'hidden',
          zIndex: 2
        }}
      />
      
      {/* Bottom Control Bar */}
      <div style={{ 
        position: 'absolute', 
        bottom: 0, 
        left: 0, 
        right: 0, 
        padding: '10px', 
        backgroundColor: 'rgba(0,0,0,0.5)', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        gap: '10px',
        zIndex: 3
      }}>
        <IconButton 
          onClick={toggleAudio} 
          style={{ 
            backgroundColor: isAudioMuted ?   'red':'#0A6621', 
            color: 'white' 
          }}
        >
          {isAudioMuted ? <MicOff /> : <Mic />}
        </IconButton>
        <IconButton 
          onClick={toggleVideo} 
          style={{ 
            backgroundColor: isVideoMuted ?  'red':'#0A6621', 
            color: 'white' 
          }}
        >
          {isVideoMuted ? <VideocamOff /> : <Videocam />}
        </IconButton>
        <IconButton 
          onClick={leaveChannel} 
          style={{ 
            backgroundColor: 'red', 
            color: 'white' 
          }}
        >
          <CallEnd />
        </IconButton>
        <Receiver />
      </div>
    </div>
  );
};

export default PatientVideoV2;