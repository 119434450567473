import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { hostConfig } from '../../config';

const LineCallback = () => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ show: false, message: '', severity: 'success' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        console.log(code, state, "code & state in linecallback")

        if (code && state) {
            const [userType] = state.split('_');
            sendCodeToBackend(code, userType);
        }
    }, []);

    const sendCodeToBackend = async (code, userType) => {
        try {
            const response = await axios.post(`${hostConfig.API_BASE}/auth/line/callback`, { code, userType });

            if (response.data.login_status) {
                setAlert({ show: true, message: 'Login successful!', severity: 'success' });
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('userUuid', response.data.userUuid);
                localStorage.setItem('userType', userType);
                setTimeout(() => navigate(`/${userType}dashboard`), 1000);
            } else if (response.data.user_created) {
                setSnackbarOpen(true);
                setTimeout(() => navigate(`/${userType}login`), 2000);
            }
        } catch (error) {
            console.error('Error sending code to backend:', error);
            setAlert({ show: true, message: 'Authentication failed', severity: 'error' });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div>
            {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
            <div>Processing LINE Authentication...</div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Account created successfully! Redirecting to login..."
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </div>
    );
};

export default LineCallback;