import React from 'react';
import { Card, CardContent, Box, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StackedCards = () => {
  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const detailButton = t('HowtoUse.Button');
  const Box1 = t('HowtoUse.Box1');
  const Box2 = t('HowtoUse.Box2');
  const Box3 = t('HowtoUse.Box3');

  const cardData = [
    { text: Box1, buttonText: detailButton, color: "#FACEF3"},
    { text: Box2, buttonText: detailButton,color: "#A1E1E0" },
    { text: Box3, buttonText: detailButton,color: "#FDE4BE" },
    // Add more card data if needed
  ];

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
      {cardData.map((card, index) => (
        <Card key={index} sx={{ margin: 2,backgroundColor: '#F2F4E6' }} elevation={0} > {/* Added border */}
          <CardContent sx={{ 
            width: '80%', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between', 
            height: '100%',
            border: '1px solid black', 
            borderRadius: '20px',
            backgroundColor: '#F2F4E6',
            textAlign: 'left',
            fontSize: 22
            }}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography variant="body1">{card.text}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}> {/* Button right and bottom aligned */}
                <Button 
                variant="contained" 
                sx={{ 
                    backgroundColor: card.color, 
                    color: 'black',
                    width: '50%', // Makes the button wider
                    boxShadow: 'none', // Removes the shadow
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: '#0A6621', // Change to your desired hover background color
                        color: '#F2F4E6' // Change to your desired hover text color
                      }
                     }} >
                    {card.buttonText}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default StackedCards;
