import React, { useEffect, useState } from "react";
import axios from 'axios';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import 'bootstrap/dist/css/bootstrap.min.css';
import BookingModal from "./BookingModal";
import { hostConfig } from "../../config";
import TherapistVideoPlayer from "./TherapistVideo";
import { useTranslation } from "react-i18next";




const TherapistCards = ({ selectedFilters, people }) => {
  const [data, setData] = useState([]);
  const { t,i18n } = useTranslation();

  useEffect(() => {
    // Fetch data from the backend API
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`${hostConfig.API_BASE}/therapistcards`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

const photos = ["TherapistVideo/Movie_on_2023-09-12_at_13.02_mdgeii","TherapistVideo/xloud81vb1spoqbtlwbe"]


  // for the filter function / filter bar.  
  const filteredPeople = data.filter((filteredperson) => {
    return (
      (selectedFilters.filter1 === "" ||
        filteredperson.practice === selectedFilters.filter1) &&
      (selectedFilters.filter2 === "" ||
        filteredperson.price_range === selectedFilters.filter2) &&
      (selectedFilters.filter3 === "" ||
        filteredperson.customer_count === selectedFilters.filter3)
    );
  });

  return (
    <div
      className="parentcardcontainer"
      style={{ width: "100%", height: "100%" }}
    >
      
      <div className="cardcontainer" style={{ margin: "4em" }}>
        <Row xs={1} md={3} className="g-4">
          {filteredPeople.map((person) => (
            <Col>
              <Card style={{ width: "17rem", margin: "0em 1em 1em" }} key = {person.therapist_uuid}>
                <Card.Img
                  variant="top"
                  src="holder.js/100px180?text=Image cap"
                />
                <TherapistVideoPlayer width = {272} height = {210} publicid = {person.therapist_uuid} controls/>
                <Card.Body>
                  <Card.Title>
                    {person.first_name} {person.last_name} 
                  </Card.Title>
                  <Card.Text>{person.introduction}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                  
                  <ListGroup.Item>{t('searchpage.therapistcards.rating')} {person.rating}</ListGroup.Item>
                  <ListGroup.Item>{t('searchpage.therapistcards.practice')} {person.practice}</ListGroup.Item>
                  <ListGroup.Item>
                  {t('searchpage.therapistcards.N_of_Customers')} {person.customer_count}
                  </ListGroup.Item>
                  <ListGroup.Item>
                  {t('searchpage.therapistcards.price_range')} {person.hourly_rate}
                  </ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <BookingModal 
                    schedules={person.booked_dates}
                    firstname={person.first_name}
                    lastname = {person.last_name}
                    uuid = {person.therapist_uuid}
                    description = {person.introduction}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};


export default TherapistCards;








