import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import PatientList from './InvitePatientList';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';

const EventModal = ({
  open,
  handleClose,
  eventTitle,
  setEventTitle,
  eventDate,
  setEventDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  isOnline,
  setIsOnline,
  invites,
  setInvites,
  comments,
  setComments,
  handleCreateEvent,
  modalStyle,
  selectedTimezone,
  setSelectedTimezone
}) => {
  const { t } = useTranslation();

  const timezoneOptions = [
    { value: 'Asia/Tokyo', label: 'Japan' },
    { value: 'America/New_York', label: 'US Eastern' },
    { value: 'America/Chicago', label: 'US Central' },
    { value: 'America/Denver', label: 'US Mountain' },
    { value: 'America/Los_Angeles', label: 'US Pacific' },
  ];

  const handleDateChange = (e) => {
    console.log('Date change event:', e.target.value);
    setEventDate(e.target.value);
  };

  const addMinutesToTime = (time, minutesToAdd) => {
    console.log('addMinutesToTime input:', time, minutesToAdd);
    if (!time) return '';
    const [hours, minutes] = time.split(':').map(Number);
    console.log('Parsed hours and minutes:', hours, minutes);
    const timeInMinutes = hours * 60 + minutes + minutesToAdd;
    console.log('Total minutes:', timeInMinutes);

    const newHours = Math.floor(timeInMinutes / 60);
    const newMinutes = timeInMinutes % 60;
    console.log('New hours and minutes before padStart:', newHours, newMinutes);

    const paddedHours = newHours.toString().padStart(2, '0');
    const paddedMinutes = newMinutes.toString().padStart(2, '0');
    console.log('Padded hours and minutes:', paddedHours, paddedMinutes);

    return `${paddedHours}:${paddedMinutes}`;
  };

  const calculatedEndTime = startTime ? addMinutesToTime(startTime, 60) : '';
  console.log('Calculated end time:', calculatedEndTime);

  console.log('Current state values:', {
    eventDate,
    startTime,
    endTime,
    calculatedEndTime
  });

  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="create-event-modal"
      aria-describedby="create-event-modal-description"
      style={{ position: 'absolute', ...modalStyle }}
    >
      <Box sx={{ background: 'white', padding: 2, border: '1px solid #000', boxShadow: 24 }}>
        <TextField
          label={t('therapistdashboard.scheduler.event.eventtitle')}
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t('therapistdashboard.scheduler.event.eventdate')}
          type="date"
          value={eventDate || ''}
          onChange={handleDateChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t('therapistdashboard.scheduler.event.starttime')}
          type="time"
          value={startTime || ''}
          onChange={(e) => setStartTime(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t('therapistdashboard.scheduler.event.endtime')}
          type="time"
          value={calculatedEndTime}
          onChange={(e) => setEndTime(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="timezone-select-label">{t('therapistdashboard.scheduler.event.timezone')}</InputLabel>
          <Select
            labelId="timezone-select-label"
            id="timezone-select"
            value={selectedTimezone}
            label={t('therapistdashboard.scheduler.event.timezone')}
            onChange={(e) => setSelectedTimezone(e.target.value)}
          >
            {timezoneOptions.map(tz => (
              <MenuItem key={tz.value} value={tz.value}>{tz.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ margin: '10px 0' }}>
          <Button variant={isOnline ? "contained" : "outlined"} onClick={() => setIsOnline(true)}>
            {t('therapistdashboard.scheduler.event.online')}
          </Button>
          <Button variant={!isOnline ? "contained" : "outlined"} onClick={() => setIsOnline(false)} style={{ marginLeft: '10px' }}>
            {t('therapistdashboard.scheduler.event.inperson')}
          </Button>
        </div>
        <PatientList />
        <TextField
          label={t('therapistdashboard.scheduler.event.comments')}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          multiline
          rows={4}
          fullWidth
          margin="normal"
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Button onClick={() => handleCreateEvent(selectedTimezone)}>
            {t('therapistdashboard.scheduler.event.savebutton')}
          </Button>
          <Button onClick={handleClose}>
            {t('therapistdashboard.scheduler.event.cancel')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default EventModal;