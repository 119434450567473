import React from "react";
import axios from "axios"; 
import { hostConfig } from "../../../config";

export const VideoControls = ({ isConnected, calling, setMic, micOn, setCamera, cameraOn, setCalling }) => {
    const handleHangUp = async () => {
        // Logic to switch the calling state
        setCalling(prevCalling => !prevCalling);
        const session_uuid = localStorage.getItem('session_uuid');
        
        // If currently in a call, send a request to the backend
        if (calling) {
            try {
                const response = await axios.post(`${hostConfig.API_BASE}/therapistleaveroom`, 
                {session_uuid:session_uuid });
                console.log(response.data); // Handle the response as needed
            } catch (error) {
                console.error('Hang up error:', error);
                // Handle error (e.g., show an error message)
            }
        }
    };
    return (
        <div className="control">
            <div className="left-control">
                <button className="btn" onClick={() => setMic((prevMic) => !prevMic)}>
                    <i className={`i-microphone ${!micOn ? "off" : ""}`} />
                </button>
                <button className="btn" onClick={() => setCamera((prevCamera) => !prevCamera)}>
                    <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
                </button>
            </div>
            <button
                className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
                onClick={handleHangUp}
            >
                
                {calling ? <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
            </button>
            
        </div>
    )
};

