import { Cloudinary } from "@cloudinary/url-gen";
import { React,useEffect, useRef } from 'react';
//import "cloudinary-video-player/dist/cld-video-player.min.css";


const credential = () => {
    const cld = new Cloudinary({ cloud: { cloudName: 'hkqlkwtmu' } });
};

const cloudName = "hkqlkwtmu"




const TherapistVideoPlayer = (props)=>{
  const {width, height,publicid } = props
  const cloudinaryRef = useRef();
  const videoRef = useRef();
  const posterUrl = "https://relax-job.com/more/wp-content/uploads/2019/11/shutterstock_722761687-min.jpg"

  useEffect(() => {
    if ( cloudinaryRef.current ) return;

    cloudinaryRef.current = window.cloudinary;
    cloudinaryRef.current.videoPlayer(videoRef.current, {
      cloud_name: cloudName
    })
  }, []);

  return (
      <video
        ref={videoRef}
        data-cld-public-id = {`TherapistVideo/${publicid}`}
        width = {width}
        height = {height}
        controls
        poster={posterUrl}
        
      />
  );
}


export default TherapistVideoPlayer;