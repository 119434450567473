import React from 'react';
import CustomerVoiceGrid from './CustomerCarousel';
import './CustomerVoice.css'
import { useTranslation } from 'react-i18next';



const CustomerVoice = () => {
  const { t, i18n } = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };


  return (
    <div className = "CustomerVoiceSection">
        <div className = "CustomerVoiceTitle">
        | {t('CustomerVoices.customer_voice_title')}
        </div>
        <div className = "CustomerVoiceGrid">
        <CustomerVoiceGrid />
        </div>
    </div>

  );
};

export default CustomerVoice;
