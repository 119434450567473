import React from 'react';
import JourneyGrid from './TheJourney';
import './TheJourneySection.css'
import { useTranslation } from 'react-i18next';



const TheJourney = () => {
  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className = "TheJourneySection">
        <div className = "TheJourneyTitle">
        | {t("Journey.Journeytitle")}
        </div>
        <div className = "TheJourneyGrid">
        <JourneyGrid />
        </div>
    </div>

  );
};

export default TheJourney;
