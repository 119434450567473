import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, isSameDay, isBefore, isToday } from 'date-fns';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { hostConfig } from '../../../../config';
import axios from 'axios';
import BookingSuccessAlert from './BookedAlert';
import { useTranslation } from "react-i18next";

const firebaseConfig = {
    apiKey: "AIzaSyAfrKlyWtplvug2Drol81m5pUTXwDdpEDw",
    authDomain: "ascre-65d72.firebaseapp.com",
    databaseURL: "https://ascre-65d72-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ascre-65d72",
    storageBucket: "ascre-65d72.appspot.com",
    messagingSenderId: "243468257698",
    appId: "1:243468257698:web:2f9faeeff618bce38b4afb",
    measurementId: "G-H5YNGZ8LKT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

// Common timezone options
const timezoneOptions = [
    'Asia/Tokyo',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Hong_Kong',
    'Asia/Bangkok',
    'Asia/Manila',
    'Australia/Sydney',
    'Pacific/Auckland',
    'America/Los_Angeles',
    'America/New_York',
    'UTC'
];

const InVideoCalendarModal = ({ therapistUuid }) => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('Asia/Tokyo'); // Default to Asia/Tokyo
    const [open, setOpen] = useState(true);
    const [therapistData, setTherapistData] = useState([]);
    const { t } = useTranslation();

    const clearFirebaseSession = async () => {
        try {
            const session_uuid = localStorage.getItem('session_uuid');
            if (session_uuid) {
                await set(ref(database, `session/${session_uuid}`), null);
                console.log("Firebase session cleared successfully");
            }
        } catch (error) {
            console.error("Error clearing Firebase session:", error);
        }
    };

    const handleClose = async () => {
        await clearFirebaseSession();
        setOpen(false);
    };

    useEffect(() => {
        return () => {
            clearFirebaseSession();
        };
    }, []);

    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${hostConfig.API_BASE}/therapistvideoschedule`, {
                    params: { therapist_uuid: therapistUuid }
                });
                setTherapistData(response.data[0]?.booked_dates || []);
            } catch (error) {
                console.error("Error fetching therapist data:", error);
            }
        };

        fetchData();
    }, [therapistUuid]);

    const convertDateStrings = (dateStrings) => {
        if (!Array.isArray(dateStrings)) return [];
        return dateStrings.map(dateTimeString => {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            return {
                date: new Date(year, month - 1, day),
                time: timePart.substring(0, 5)
            };
        });
    };

    const reservedDates = convertDateStrings(therapistData);

    const isTimeReservedForDate = (date, time) => {
        return reservedDates.some(reservation => {
            const adjustedReservationDate = new Date(
                reservation.date.getFullYear(),
                reservation.date.getMonth(),
                reservation.date.getDate()
            );
            const formattedReservationDate = format(adjustedReservationDate, 'yyyy-MM-dd');
            const formattedSelectedDate = format(date, 'yyyy-MM-dd');
            return formattedReservationDate === formattedSelectedDate && reservation.time === time;
        });
    };

    const handleReservationSubmit = async () => {
        const [hours, minutes] = selectedTime.split(':').map(Number);
        const reservationDateTime = new Date(selectedDate);
        reservationDateTime.setHours(hours, minutes, 0, 0);
        const formattedReservationDateTime = reservationDateTime.toISOString();
        const booking_date = formattedReservationDateTime;

        try {
            const response = await axios.post(`${hostConfig.API_BASE}/bookings`, {
                booking_date,
                therapist_uuid: therapistUuid,
                timezone: selectedTimezone // Add timezone to the request
            });

            if (response.data.session_booked === true) {
                await clearFirebaseSession();
                setOpen(false);
                <BookingSuccessAlert />;
            }
        } catch (error) {
            console.error('Error making reservation:', error);
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime('');
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };

    const handleMonthChange = (direction) => {
        setCurrentMonth(prevMonth => addMonths(prevMonth, direction));
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={ModalStyle}>
                <h2>{t('patientvideo.calendarmodal.title')}</h2>

                {/* Timezone Selector */}
                <div className="timezone-selector" style={{ marginBottom: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Timezone</InputLabel>
                        <Select
                            value={selectedTimezone}
                            onChange={(e) => setSelectedTimezone(e.target.value)}
                            style={{ marginBottom: '20px' }}
                        >
                            {timezoneOptions.map((timezone) => (
                                <MenuItem key={timezone} value={timezone}>
                                    {timezone.replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className="month-navigation">
                    <button onClick={() => handleMonthChange(-1)}>←</button>
                    <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
                    <button onClick={() => handleMonthChange(1)}>→</button>
                </div>

                <div className="ModalCalendar">
                    {daysInMonth.map((date) => (
                        <button
                            key={format(date, 'yyyy-MM-dd')}
                            onClick={() => handleDateClick(date)}
                            className={`
                                ${isSameDay(selectedDate, date) ? 'selected' : ''}
                                ${isBefore(date, today) ? 'disabled' : ''}
                            `}
                            disabled={isBefore(date, today)}
                        >
                            {format(date, 'd')}
                        </button>
                    ))}
                </div>

                {selectedDate && (
                    <div className="time-slots">
                        {['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', 
                          '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', 
                          '21:00', '22:00']
                            .filter(slot => !isTimeReservedForDate(selectedDate, slot))
                            .map(slot => (
                                <button
                                    key={slot}
                                    onClick={() => handleTimeClick(slot)}
                                    className={selectedTime === slot ? 'selected' : ''}
                                >
                                    {slot}
                                </button>
                            ))}
                    </div>
                )}

                <button 
                    className="reserve-button"
                    onClick={handleReservationSubmit}
                >
                    {selectedDate && selectedTime 
                        ? `${t('patientvideo.calendarmodal.button')} ${format(selectedDate, 'MMMM d, yyyy')} ${selectedTime} (${selectedTimezone})`
                        : t('patientvideo.calendarmodal.buttonother')}
                </button>
            </Box>
        </Modal>
    );
};

export default InVideoCalendarModal;