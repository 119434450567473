import React from "react";
import SymptomList from "./Symptoms";
import './SymptomSection.css'
import { useTranslation } from 'react-i18next';




const SymptomSection = () => {
    const { t,i18n} = useTranslation();

    const switchLanguage = (language) => {
      i18n.changeLanguage(language);
    };

    return(
        <div className = "SymptomSection">
            <div className = "SymptomTitle">
            |{t('Symptoms.Symptomtitle')}
            </div>
            <div className = "SymptomLists">
                <SymptomList />
            </div>

        </div>
    )
}

export default SymptomSection;