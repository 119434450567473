import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { hostConfig } from '../../config';
import { useNavigate } from 'react-router-dom';





function Glogin() {
const navigate = useNavigate();
  const responseMessage = async (response) => {
    //console.log(response, "response console log");

    if (response.credential) {
      try {
        // Send the authorization code to your backend server
        const res = await axios.post(`${hostConfig.API_BASE}/patientglogin`, { credential: response.credential });
        //console.log('Backend response:', res.data);
        if(res.data.user_validated === true){
          //  console.log(res.data.user.uuid);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userUuid', res.data.user.uuid);
            localStorage.setItem('userTimezone', res.data.user.preferred_timezone);
            navigate('/patientdashboard');

        }else{
            console.log('try again');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <div>
      <br />
      <GoogleLogin 
      clientId='89676155600-b024scjh0qd25mp96ce1mie6h9nqtdcb.apps.googleusercontent.com'
      onSuccess={responseMessage} 
      onError={errorMessage} />
    </div>
  );
};







export default Glogin;