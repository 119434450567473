import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { solid } from '@cloudinary/url-gen/actions/border';
import { useTranslation } from 'react-i18next';



const CustomerVoiceGrid = () => {
  const { t, i18n } = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const customervoice1 = t('CustomerVoices.customervoice1');
  const customervoice2 = t('CustomerVoices.customervoice2');
  const customervoice3 = t('CustomerVoices.customervoice3');
  const customervoice4 = t('CustomerVoices.customervoice4');
  const customername1 = t('CustomerVoices.customername1');
  const customername2 = t('CustomerVoices.customername2');
  const customername3 = t('CustomerVoices.customername3');
  const customername4 = t('CustomerVoices.customername4');


  const squares = new Array(16).fill(null);
  const sentencePairs = [
    { comment: customervoice1, name: customername1 },
    { comment: customervoice2, name: customername2 },
    { comment: customervoice3, name: customername3},
    { comment: customervoice4, name: customername4 },
    // Add more pairs if needed
  ];

  const imageData = [
    { src: 'image1', alt: "step 1" },
    { src: 'image2', alt: "Step 2" },
    { src: 'image3', alt: "Step 3" },
    { src: 'image4', alt: "Step 4" },
    // ... Add more objects if you have more than 4 images
  ];

  return (
    <Box
    sx={{
      overflowX: 'auto', // Allow horizontal scrolling
      display: 'flex', // Enable flex container
      flexDirection: 'row', // Arrange items in a row
      p: 2, // Add padding around the container
    }}
  >
    {sentencePairs.map((pair, index) => (
      <Box key={index} sx={{ width: '100%', maxWidth: 300, p: 1, mx: 'auto' }}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 400, // Adjust height as needed
            backgroundColor: '#F2F4E6',
            borderRadius: '50%', // This will not be visible as a circle due to the content padding and height
          }}
          elevation={0}
        >
<Box
  sx={{
    width: 120, // Width of the circle container
    height: 120, // Height of the circle container to match the width
    borderRadius: '50%', // This makes it a circle
    overflow: 'hidden',
    mb: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <img
    src={imageData[index].src} // Make sure these are correct paths to your image assets
    alt={imageData[index].alt}
    style={{
      borderStyle: 'solid',
      maxWidth: '100%', // Adjust as needed to scale the image size
      maxHeight: '100%', // Adjust as needed to scale the image size
      objectFit: 'cover', // Keeps image aspect ratio
    }}
  />
</Box>

          <Typography 
          variant="subtitle1" 
          component="p" 
            sx={{ 
              fontSize: '15px', 
              textAlign: 'center', 
              fontWeight: 'bold',
              mb: 0, // Reduced bottom margin on comment 
            }}>
            {pair.comment}
          </Typography>
          <Typography variant="caption" component="p" 
          sx={{ 
            fontSize: '12px', 
            color: '#0A6621',
            mt: -1,
            }}>
            {pair.name}
          </Typography>
        </Paper>
      </Box>
    ))}
  </Box>
  );
};

export default CustomerVoiceGrid;
