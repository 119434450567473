import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from "react-i18next";



const CompanyOverview = () => {
  const { t,i18n } = useTranslation();
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
        {t('companypage.title')}
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
        {t('companypage.info')}
        </Typography>
        <List>
          <ListItem divider>
            <ListItemText primary={t('companypage.company')} secondary={t('companypage.companycontent')} />
          </ListItem>
          <ListItem divider>
            <ListItemText primary={t('companypage.founding')} secondary={t('companypage.foundingcontent')} />
          </ListItem>
          <ListItem divider>
            <ListItemText primary={t('companypage.money')} secondary={t('companypage.moneycontent')} />
          </ListItem>
          <ListItem divider>
            <ListItemText primary={t('companypage.staff')} secondary={t('companypage.staffcontent')}  />
          </ListItem>
          <ListItem divider>
            <ListItemText primary={t('companypage.address')} secondary={t('companypage.addresscontent')} />
          </ListItem>
          {/* Add more list items as needed */}
        </List>
        {/* Add more content as needed */}

        {/* Add more content as needed */}
      </Paper>
    </Container>
  );
};

export default CompanyOverview;
