import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

// SVG for Line logo
const LineLogoSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white">
    <path d="M19.311 12.002c0-3.321-3.334-6.023-7.432-6.023s-7.432 2.702-7.432 6.023c0 2.981 2.646 5.478 6.224 5.95.242.052.572.161.656.369.075.189.049.485.024.675l-.1.607c-.031.18-.145.705.618.384 .761-.321 4.105-2.419 5.602-4.139 1.033-1.134 1.54-2.286 1.54-3.846zm-10.468 1.771h-1.65a.252.252 0 0 1-.252-.252v-3.602a.252.252 0 0 1 .252-.252h.374a.252.252 0 0 1 .252.252v2.824h1.024a.252.252 0 0 1 .252.252v.526a.252.252 0 0 1-.252.252zm5.657-1.659a.252.252 0 0 1-.252.252h-2.453v.763h2.453a.252.252 0 0 1 .252.252v.526a.252.252 0 0 1-.252.252h-3.079a.252.252 0 0 1-.252-.252v-3.602a.252.252 0 0 1 .252-.252h3.079a.252.252 0 0 1 .252.252v.526a.252.252 0 0 1-.252.252h-2.453v.763h2.453a.252.252 0 0 1 .252.252v.518zm3.783-1.659v2.289l-1.951-2.658a.361.361 0 0 0-.297-.155h-.029a.252.252 0 0 0-.252.252v3.602a.252.252 0 0 0 .252.252h.637a.252.252 0 0 0 .252-.252v-2.289l1.951 2.658a.361.361 0 0 0 .297.155h.029a.252.252 0 0 0 .252-.252v-3.602a.252.252 0 0 0-.252-.252h-.637a.252.252 0 0 0-.252.252z"/>
  </svg>
);

const LineButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#06C755',
  color: '#FFFFFF',
  fontFamily: '"Helvetica Neue", Arial, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  padding: '10px 16px',
  fontSize: '16px',
  lineHeight: '24px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  '&:hover': {
    backgroundColor: '#05A847',
  },
}));

const LineAuthButton = ({ userType }) => {
  const { t } = useTranslation();

  const handleLineLogin = () => {
    const clientId = 2000756017;
    const redirectUri = "https://www.ascreforme.com/callback";
    const state = `${userType}_${uuidv4()}`;
    const scope = 'profile openid email';

    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=${scope}`;
        
    window.location.href = lineAuthUrl;
  };

  return (
    <LineButton onClick={handleLineLogin} startIcon={<LineLogoSVG />}>
      {t(`${userType}login.linelogin`)}
    </LineButton>
  );
};

export default LineAuthButton;