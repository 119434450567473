import React from "react";
import { LocalUser, RemoteUser } from "agora-rtc-react";

export const UserVideos = ({ isConnected, localMicrophoneTrack, cameraOn, micOn, localCameraTrack, remoteUsers }) => {
    return (
                <div className="user-list">
                    <div className="user">
                        <LocalUser
                            audioTrack={localMicrophoneTrack}
                            cameraOn={cameraOn}
                            micOn={micOn}
                            videoTrack={localCameraTrack}
                            cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                        >
                            <samp className="user-name">You</samp>
                        </LocalUser>
                    </div>
                    {remoteUsers.map((user) => (
                        <div className="user" key={user.uid}>
                            <RemoteUser cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" user={user}>
                                <samp className="user-name">{user.uid}</samp>
                            </RemoteUser>
                        </div>
                    ))}
                </div>
            
        
    );
};







