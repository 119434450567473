import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import ChargeButton from "./patientcharge";
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';

// Mock data for local development
const mockData = [
    {
        patient_full_name: "John Doe",
        booked_session_date_timestamp: "2024-11-07T10:00:00Z",
        hourly_rate: 15000,
        currency: "USD",
        charged: false,
        duration_formatted: "60 minutes",
        preferred_timezone: "America/New_York",
        patient_uuid: "mock-uuid-1",
        therapist_uuid: "mock-therapist-1",
        session_uuid: "mock-session-1"
    },
    {
        patient_full_name: "Jane Smith",
        booked_session_date_timestamp: "2024-11-08T11:00:00Z",
        hourly_rate: 10000,
        currency: "JPY",
        charged: true,
        duration_formatted: "45 minutes",
        preferred_timezone: "Asia/Tokyo",
        patient_uuid: "mock-uuid-2",
        therapist_uuid: "mock-therapist-2",
        session_uuid: "mock-session-2"
    }
];

// Currency formatter utility
const formatCurrency = (amount, currency) => {
    if (!amount) return '';

    try {
        const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

        switch(currency?.toUpperCase()) {
            case 'USD':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(numericAmount / 100); // Divide by 100 for USD

            case 'JPY':
                return new Intl.NumberFormat('ja-JP', {
                    style: 'currency',
                    currency: 'JPY',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }).format(numericAmount); // Don't divide JPY

            default:
                return numericAmount.toString();
        }
    } catch (error) {
        console.error('Error formatting currency:', error);
        return '';
    }
};

const History = () => {
    const { t } = useTranslation();
    const [therapists, setTherapists] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Use mock data in development environment
                if (process.env.NODE_ENV === 'development') {
                    setTherapists(mockData);
                    return;
                }

                const response = await axios.get(`${hostConfig.API_BASE}/therapisthistory`);
                setTherapists(response.data);
            } catch (error) {
                console.error('Error fetching client data:', error);
                // Fallback to mock data if there's an error
                setTherapists(mockData);
            }
        };

        fetchData();
    }, []);

    const handleSessionJoin = async (session_uuid) => {
        try {
            const response = await axios.post(`${hostConfig.API_BASE}/therapistjoinroom`, { session_uuid });
            console.log('Session join successful', response.data);
        } catch (error) {
            console.error('Error joining session:', error);
        }
    };

    const columns = [
        { 
            field: 'patient_full_name', 
            headerName: t('therapistdashboard.history.customername'), 
            width: 200 
        },
        { 
            field: 'booked_session_date_timestamp', 
            headerName: t('therapistdashboard.history.date'), 
            width: 250,
            renderCell: (params) => {
                const formattedDate = moment(params.value)
                    .tz(params.row.preferred_timezone || 'UTC')
                    .format('YYYY-MM-DD HH:mm');
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {formattedDate}
                    </div>
                );
            }
        },
        { 
            field: 'hourly_rate', 
            headerName: t('therapistdashboard.history.hourlyrate'), 
            width: 150,
            renderCell: (params) => {
                // Format currency based on the currency type
                return formatCurrency(params.value, params.row.currency);
            }
        },
        {
            field: 'charge',
            headerName: t('therapistdashboard.history.chargebutton'),
            width: 150,
            renderCell: (params) => {
                console.log('Charge cell params:', params.row);
                if (params.row.charged) {
                    return (
                        <Typography color="textSecondary">
                            Charged
                        </Typography>
                    );
                }
                return (
                    <ChargeButton 
                        patient_uuid={params.row.patient_uuid}
                        hourly_rate={params.row.hourly_rate}
                        currency={params.row.currency}
                        therapist_uuid={params.row.therapist_uuid}
                        patient_full_name={params.row.patient_full_name}
                        session_uuid={params.row.session_uuid}
                    />
                );
            }
        },
        { 
            field: 'duration_formatted', 
            headerName: t('therapistdashboard.history.duration'), 
            width: 200 
        },
    ];

    return (
        <div className="therapist-clientlist">
            <div className="data-grid-container" style={{ height: 400, width: 1000 }}>
                <DataGrid
                    rows={therapists.map((therapist, index) => ({
                        id: index,
                        ...therapist,
                        charged: Boolean(therapist.charged)
                    }))}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
        </div>
    );
}

export default History;