import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Tooltip,
    TextField,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Paper,
    Fade,
    Snackbar,
    Alert
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    CalendarMonth,
    DragIndicator,
    Close as CloseIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { hostConfig } from '../../../config';

const mockPatients = [
    { uuid: 'mock-uuid-1', name: 'John Doe', patient_full_name: 'John Doe' },
    { uuid: 'mock-uuid-2', name: 'Jane Smith', patient_full_name: 'Jane Smith' },
    { uuid: 'mock-uuid-3', name: 'Alice Johnson', patient_full_name: 'Alice Johnson' },
    { uuid: 'mock-uuid-4', name: 'Bob Wilson', patient_full_name: 'Bob Wilson' }
];

const timezoneOptions = [
    { value: 'Asia/Tokyo', label: 'Japan' },
    { value: 'America/New_York', label: 'US Eastern' },
    { value: 'America/Chicago', label: 'US Central' },
    { value: 'America/Denver', label: 'US Mountain' },
    { value: 'America/Los_Angeles', label: 'US Pacific' },
];

const generateMockEvents = () => {
    const events = [];
    const startDate = moment().startOf('week');

    // Generate multiple events per day
    for (let day = 0; day < 7; day++) {
        const eventsPerDay = Math.floor(Math.random() * 3) + 1; // 1-3 events per day

        for (let i = 0; i < eventsPerDay; i++) {
            const patient = mockPatients[Math.floor(Math.random() * mockPatients.length)];
            const hour = 9 + Math.floor(Math.random() * 8); // Events between 9 AM and 5 PM

            const eventDate = startDate.clone().add(day, 'days').hour(hour);

            events.push({
                id: `mock-event-${day}-${i}`,
                session_uuid: `session-${day}-${i}`,
                title: `Session with ${patient.name}`,
                booked_session_date_timestamp: eventDate.toISOString(),
                patient_uuid: patient.uuid,
                patient_full_name: patient.name,
                meeting_token: `mock-token-${day}-${i}`,
                is_enabled: true,
                created_at: eventDate.subtract(1, 'day').toISOString(),
                updated_at: eventDate.toISOString()
            });
        }
    }
    return events;
};

const EventOverlay = ({ event, rowStart, dayIndex, duration, onClick }) => {
    const [position, setPosition] = useState(null);

    useEffect(() => {
        const calculatePosition = () => {
            try {
                const cellId = `cell-${rowStart}-${dayIndex}`;
                const cell = document.getElementById(cellId);

                if (!cell) return null;

                return {
                    height: (cell.offsetHeight * duration) - 4,
                    width: cell.offsetWidth - 4,
                };
            } catch (error) {
                console.error('Error calculating position:', error);
                return null;
            }
        };

        const updatePosition = () => {
            const newPosition = calculatePosition();
            if (newPosition) {
                setPosition(newPosition);
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => {
            window.removeEventListener('resize', updatePosition);
        };
    }, [rowStart, dayIndex, duration]);

    if (!position) return null;

    console.log('Rendering event overlay:', {
        event,
        title: event.title,
        patient_full_name: event.patient_full_name,
        patient_name: event.patient_name
    });

    return (
        <div
            style={{
                position: 'absolute',
                gridColumn: `${dayIndex + 3}`, // +2 because of time column
                gridRow: `${rowStart + 1}`, // +1 because grid starts at 1
                width: position.width,
                height: position.height,
                backgroundColor: '#1b5e20',
                color: 'white',
                padding: '4px',
                margin: '2px',
                fontSize: '12px',
                overflow: 'hidden',
                cursor: 'pointer',
                zIndex: 10,
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                transition: 'all 0.2s ease',
            }}
            onClick={(e) => {
                e.stopPropagation();
                onClick(event);
            }}
        >
            <div style={{ fontWeight: 'bold' }}>{event.title}</div>
            <div style={{ fontSize: '10px' }}>
                {moment(event.start).format('HH:mm')} - {moment(event.start).add(duration, 'hours').format('HH:mm')}
            </div>
        </div>
    );
};

const CalendarScheduler = ({ isOpen, onClose }) => {
    // States
    const [events, setEvents] = useState([]);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventStart, setEventStart] = useState('');
    const [currentWeekStart, setCurrentWeekStart] = useState(getSunday(new Date()));
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState(timezoneOptions[0].value);
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState('');
    const [notification, setNotification] = useState({ open: false, message: '', type: 'info' });
    const [isDragging, setIsDragging] = useState(false);

    const { t } = useTranslation();
    const gridRef = useRef(null);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hours = Array.from({ length: 14 }, (_, i) => i + 7); // 7 AM to 8 PM

    // Utility Functions
    function getSunday(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day;
        return new Date(d.setDate(diff));
    }

    function formatDate(date) {
        return moment(date).format('MMM D');
    }

    const showNotification = (message, type = 'info') => {
        setNotification({
            open: true,
            message,
            type,
        });
    };

    // Data Fetching
    useEffect(() => {
        fetchTherapistData();
        fetchPatients();
    }, [selectedTimezone]);

    const fetchTherapistData = async () => {
        setIsLoading(true);
        setError(null);
    
        try {
            let eventsData;
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                eventsData = generateMockEvents();
                await new Promise(resolve => setTimeout(resolve, 500));
            } else {
                const response = await axios.get(`${hostConfig.API_BASE}/gettherapistschedule`);
                eventsData = response.data;
            }
    
            console.log('Raw events data:', eventsData);
    
            const transformedEvents = eventsData.map(event => {
                const eventDateTime = moment.utc(event.booked_session_date_timestamp).tz(selectedTimezone);
                
                // Combine first and last name if available
                const patientName = event.first_name && event.last_name 
                    ? `${event.first_name} ${event.last_name}`
                    : event.patient_full_name || event.patient_name || 'Unknown';
    
                console.log('Processing event:', {
                    original: event,
                    combinedName: patientName
                });
    
                const transformedEvent = {
                    ...event,
                    id: event.id || event.session_uuid,
                    patient_full_name: patientName, // Store the combined name
                    title: `Session with ${patientName}`,
                    start: eventDateTime.toDate(),
                    day: eventDateTime.format('ddd'),
                    date: eventDateTime.format('M/D/YYYY'),
                    hour: eventDateTime.format('HH:mm'),
                    startTime: eventDateTime.format('HH:mm'),
                    endTime: eventDateTime.clone().add(1, 'hour').format('HH:mm'),
                };
    
                console.log('Transformed event:', transformedEvent);
                return transformedEvent;
            });
    
            console.log('Final transformed events:', transformedEvents);
            setEvents(transformedEvents);
        } catch (error) {
            console.error('Error fetching therapist data:', error);
            setError(error);
            showNotification('Failed to load schedule', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPatients = async () => {
        try {
            let patientsData;
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                patientsData = mockPatients;
                await new Promise(resolve => setTimeout(resolve, 500));
            } else {
                const response = await axios.get(`${hostConfig.API_BASE}/clientlist`);
                patientsData = response.data;
                console.log('Raw patients data from API:', patientsData);
            }
    
            console.log('Processing patients data:', patientsData);
    
            const uniquePatients = patientsData.reduce((acc, patient) => {
                console.log('Processing patient:', patient);
                if (!acc.some(p => p.uuid === patient.patient_uuid || p.uuid === patient.uuid)) {
                    const processedPatient = {
                        name: patient.patient_full_name || patient.name,
                        uuid: patient.patient_uuid || patient.uuid
                    };
                    console.log('Added patient:', processedPatient);
                    acc.push(processedPatient);
                }
                return acc;
            }, []);
    
            console.log('Final processed patients:', uniquePatients);
            setPatients(uniquePatients);
        } catch (error) {
            console.error('Error fetching patients:', error);
            showNotification('Failed to load patients', 'error');
        }
    };

    // Event Handlers
    const handleDragEnd = async (result) => {
        setIsDragging(false);
        if (!result.destination) return;

        const { draggableId, destination } = result;
        const event = events.find(e => e.id === draggableId);
        if (!event) return;

        const newDate = moment(currentWeekStart)
            .add(destination.droppableId, 'days')
            .hour(destination.index + 7);

        try {
            await handleEventUpdate(event, newDate);
            showNotification('Event moved successfully', 'success');
        } catch (error) {
            showNotification('Failed to move event', 'error');
        }
    };

    const handleEventUpdate = async (event, newDate) => {
        const updateData = {
            id: event.id,
            patient_uuid: event.patient_uuid,
            timezone: selectedTimezone,
            startTime: newDate.format(),
        };

        try {
            await axios.put(`${hostConfig.API_BASE}/edittherapistschedule`, updateData);
            await fetchTherapistData();
        } catch (error) {
            throw new Error('Failed to update event');
        }
    };

    // More Event Handlers
    const handleCellClick = (date, hour) => {
        const slotDate = moment.tz(date, selectedTimezone).hour(hour).minute(0);
        setSelectedEvent(null);
        setEventStart(slotDate.format('YYYY-MM-DDTHH:mm'));
        setSelectedPatient('');
        setIsEventModalOpen(true);
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setEventStart(moment(event.start).tz(selectedTimezone).format('YYYY-MM-DDTHH:mm'));
        setSelectedPatient(event.patient_uuid);
        setIsEventModalOpen(true);
    };

    const handleSaveEvent = useCallback(async () => {

        if (!selectedPatient) {
            showNotification('Please select a patient', 'error');
            return;
        }
    
        if (!eventStart) {
            showNotification('Please select a start time', 'error');
            return;
        }
        console.log('Selected Patient:', selectedPatient);
        console.log('Patients List:', patients);

        const localEventStart = moment.tz(eventStart, selectedTimezone);
        const utcEventStart = localEventStart.clone().utc();

        const eventData = {
            id: selectedPatient,
            timezone: selectedTimezone,
            startTime: utcEventStart.format(),

            
        };
        

        console.log('Saving event with data:', eventData);

        try {
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                // Simulate API call in development
                await new Promise(resolve => setTimeout(resolve, 500));
                if (selectedEvent) {
                    // Update existing event in mock data
                    setEvents(prevEvents => prevEvents.map(event =>
                        event.id === selectedEvent.id
                            ? { ...event, ...eventData, updated_at: new Date().toISOString() }
                            : event
                    ));
                } else {
                    // Add new event to mock data
                    const newEvent = {
                        id: `mock-event-${Date.now()}`,
                        session_uuid: `session-${Date.now()}`,
                        ...eventData,
                        patient_full_name: patients.find(p => p.uuid === selectedPatient)?.name,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                    };
                    setEvents(prevEvents => [...prevEvents, newEvent]);
                }
            } else {
                if (selectedEvent) {
                    await axios.put(`${hostConfig.API_BASE}/edittherapistschedule`, {
                        ...eventData,
                        id: selectedEvent.id,
                    });
                } else {
                    const response = await axios.post(`${hostConfig.API_BASE}/savetherapistschedule`, eventData);
                    console.log('saveshcedue / Save response:', response.data);
                }
                await fetchTherapistData();
            }

            showNotification(selectedEvent ? 'Event updated successfully' : 'Event created successfully', 'success');
        } catch (error) {
            console.error('Error saving event:', error);
            showNotification('Failed to save event', 'error');
        }

        setIsEventModalOpen(false);
        setSelectedEvent(null);
        setEventStart('');
        setSelectedPatient('');
    }, [selectedEvent, eventStart, selectedTimezone, selectedPatient, patients]);

    const handleDeleteEvent = useCallback(async () => {
        if (selectedEvent) {
            try {
                if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                    // Simulate API call in development
                    await new Promise(resolve => setTimeout(resolve, 500));
                    setEvents(prevEvents => prevEvents.filter(event => event.id !== selectedEvent.id));
                } else {
                    await axios.post(`${hostConfig.API_BASE}/deletetherapistschedule`, {
                        session_uuid: selectedEvent.id
                    });
                    await fetchTherapistData();
                }
                showNotification('Event deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting event:', error);
                showNotification('Failed to delete event', 'error');
            }
            setIsEventModalOpen(false);
            setSelectedEvent(null);
            setEventStart('');
            setSelectedPatient('');
        }
    }, [selectedEvent]);

    const changeWeek = (increment) => {
        const newDate = moment(currentWeekStart).add(7 * increment, 'days').toDate();
        setCurrentWeekStart(newDate);
    };

    // Render Method
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: {
                    maxHeight: '90vh',
                    maxWidth: '95vw',
                },
            }}
        >
            <DialogTitle
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(0,0,0,0.12)'
                }}
            >
                <Typography variant="h6">Schedule Manager</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="500px">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error" style={{ padding: '20px', textAlign: 'center' }}>
                        Error loading schedule: {error.message}
                    </Typography>
                ) : (
                    <Box padding="16px">
                        {/* Calendar Header */}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            marginBottom="16px"
                        >
                            <IconButton onClick={() => changeWeek(-1)}>
                                <ChevronLeft />
                            </IconButton>
                            <Typography variant="h6">
                                {formatDate(currentWeekStart)} - {formatDate(moment(currentWeekStart).add(6, 'days'))}
                            </Typography>
                            <IconButton onClick={() => changeWeek(1)}>
                                <ChevronRight />
                            </IconButton>
                        </Box>

                        {/* Timezone Selector */}
                        <FormControl size="small" style={{ marginBottom: '16px', minWidth: 120 }}>
                            <InputLabel>Timezone</InputLabel>
                            <Select
                                value={selectedTimezone}
                                onChange={(e) => setSelectedTimezone(e.target.value)}
                                label="Timezone"
                            >
                                {timezoneOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Calendar Grid */}
                        <Box
                            className="calendar-grid"
                            style={{
                                height: '500px',
                                overflow: 'auto',
                                position: 'relative',
                                paddingTop: '50px',
                                paddingLeft: '50px'
                            }}
                            ref={gridRef}
                        >
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto repeat(7, 1fr)',
                                gridTemplateRows: `repeat(${hours.length}, 48px)`,
                                gap: '0px',
                                minWidth: '800px',
                                position: 'relative'
                            }}>
                                {/* Sticky Header */}
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: '50px',
                                    backgroundColor: '#fff',
                                    zIndex: 2,
                                    display: 'grid',
                                    gridTemplateColumns: 'auto repeat(7, 1fr)',
                                    gap: '4px'
                                }}>
                                    <div style={{ backgroundColor: '#fff' }}></div>
                                    {days.map((day, index) => {
                                        const date = moment(currentWeekStart).add(index, 'days');
                                        return (
                                            <div key={day} style={{
                                                textAlign: 'center',
                                                backgroundColor: '#fff',
                                                padding: '8px',
                                                borderBottom: '1px solid rgba(0,0,0,0.12)'
                                            }}>
                                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{day}</Typography>
                                                <Typography variant="caption">{formatDate(date)}</Typography>
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* Time Column */}
                                <div style={{
                                    gridColumn: '1',
                                    display: 'grid',
                                    gridTemplateRows: `repeat(${hours.length}, 48px)`,
                                }}>
                                    {hours.map(hour => (
                                        <div key={hour} style={{
                                            padding: '0 8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                        }}>
                                            {`${hour}:00`}
                                        </div>
                                    ))}
                                </div>

                                {/* Grid Cells */}
                                {hours.map((hour, rowIndex) => (
                                    <React.Fragment key={hour}>
                                        {days.map((_, dayIndex) => (
                                            <div
                                                key={`${rowIndex}-${dayIndex}`}
                                                id={`cell-${rowIndex}-${dayIndex}`}
                                                style={{
                                                    gridColumn: dayIndex + 2,
                                                    gridRow: rowIndex + 1,
                                                    border: '1px solid #e0e0e0',
                                                    borderTop: rowIndex === 0 ? '1px solid #e0e0e0' : 'none',
                                                    borderLeft: dayIndex === 0 ? '1px solid #e0e0e0' : 'none',
                                                    height: '48px',
                                                    position: 'relative',
                                                }}
                                                onClick={() => handleCellClick(
                                                    moment(currentWeekStart).add(dayIndex, 'days').toDate(),
                                                    hour
                                                )}
                                            />
                                        ))}
                                    </React.Fragment>
                                ))}

                                {/* Event Overlays */}
                                {events.map(event => {
                                    const startDate = moment(event.start);
                                    const rowStart = startDate.hour() - 7;
                                    const dayIndex = startDate.diff(moment(currentWeekStart), 'days');
                                    const duration = 1;

                                    if (dayIndex >= 0 && dayIndex < 7 && rowStart >= 0) {
                                        return (
                                            <EventOverlay
                                                key={event.id}
                                                event={event}
                                                rowStart={rowStart}
                                                dayIndex={dayIndex}
                                                duration={duration}
                                                onClick={handleEventClick}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </Box>
                        {/* Event Modal */}
                        <Dialog
                            open={isEventModalOpen}
                            onClose={() => setIsEventModalOpen(false)}
                            maxWidth="sm"
                            fullWidth
                         >
                            <DialogTitle>
                                {selectedEvent ? 'Edit Session' : 'Schedule New Session'}
                            </DialogTitle>
                            <DialogContent>
                                <TextField
                                    margin="dense"
                                    label="Start Time"
                                    type="datetime-local"
                                    fullWidth
                                    value={eventStart}
                                    onChange={(e) => setEventStart(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 1800
                                    }}
                                    style={{ marginBottom: '16px' }}
                                />
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Patient</InputLabel>
                                    <Select
                                        value={selectedPatient}
                                        onChange={(e) => setSelectedPatient(e.target.value)}
                                        label="Patient"
                                    >
                                        {patients.map((patient) => (
                                            <MenuItem key={patient.uuid} value={patient.uuid}>
                                                {patient.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Typography variant="caption" style={{ display: 'block', marginTop: '8px' }}>
                                    Timezone: {timezoneOptions.find(tz => tz.value === selectedTimezone)?.label}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                {selectedEvent && (
                                    <Button
                                        color="error"
                                        onClick={handleDeleteEvent}
                                        style={{ marginRight: 'auto' }}
                                    >
                                        Delete
                                    </Button>
                                )}
                                <Button onClick={() => setIsEventModalOpen(false)}>Cancel</Button>
                                <Button
                                    onClick={handleSaveEvent}
                                    variant="contained"
                                    color="primary"
                                >
                                    {selectedEvent ? 'Update' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Notifications */}
                        <Snackbar
                            open={notification.open}
                            autoHideDuration={6000}
                            onClose={() => setNotification({ ...notification, open: false })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Alert
                                onClose={() => setNotification({ ...notification, open: false })}
                                severity={notification.type}
                                variant="filled"
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

};

// Main Button Component
const ScheduleSetterButtonV2 = () => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Tooltip title={t('therapistvideochat.controller.scheduler')} arrow>
                <IconButton
                    onClick={() => setIsCalendarOpen(true)}
                    color="primary"
                    aria-label="set schedule"
                >
                    <CalendarMonth />
                </IconButton>
            </Tooltip>
            <CalendarScheduler
                isOpen={isCalendarOpen}
                onClose={() => setIsCalendarOpen(false)}
            />
        </>
    );
};

export default ScheduleSetterButtonV2;