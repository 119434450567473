import React, { useState, useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { red, blue } from '@mui/material/colors';
import { useLocation,useNavigate } from 'react-router-dom';
import Receiver from './CalendarRequestPopup/CalendarReceiver';
import './VideoChatPage.css'
import axios from 'axios';
import { hostConfig } from '../../../config';


const appId = "5c0757f9509447ec80344d6b2557262d"; // Replace with your App ID
//const channelName = "video"; // Channel name
//const token = "007eJxTYEjRTT9yXFYthIVNptljjbJlou9h+5v1u5fGfTCf6Wo3eZMCg2mygbmpeZqlqYGliYl5arKFgbGJSYpZkpGpqbmRmVFKlmlbakMgI8Ozh/IsjAyMDCxADOIzgUlmMMkCJlkZyjJTUvMZGAB79h87"; // You can use a temp token for testing



function AgoraVideoCall() {
    const [client] = useState(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }));
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [start, setStart] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState({});
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();


    const token = location.state?.meeting_token;
    const channelName = location.state?.session_uuid;

    const toggleMic = () => {
        if (localAudioTrack) {
            if (isAudioMuted) {
                localAudioTrack.setVolume(100);
            } else {
                localAudioTrack.setVolume(0);
            }
            setIsAudioMuted(!isAudioMuted);
        }
    };

    const toggleVideo = () => {
        if (localVideoTrack) {
            if (isVideoMuted) {
                localVideoTrack.setEnabled(true);
            } else {
                localVideoTrack.setEnabled(false);
            }
            setIsVideoMuted(!isVideoMuted);
        }
    };

    useEffect(() => {
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);
        client.on("user-left", handleUserLeft);

        const joinChannel = async () => {
            if (!start && token && channelName) {
                try {
                    await client.join(appId, channelName, token, null);

                    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
                    const cameraTrack = await AgoraRTC.createCameraVideoTrack();
                    setLocalAudioTrack(microphoneTrack);
                    setLocalVideoTrack(cameraTrack);

                    cameraTrack.play("patient-local-container");
                    await client.publish([microphoneTrack, cameraTrack]);

                    setStart(true);
                } catch (error) {
                    console.error("Error joining Agora channel:", error);
                }
            }
        };

        joinChannel();

        return () => {
            client.off("user-published", handleUserPublished);
            client.off("user-unpublished", handleUserUnpublished);
            client.off("user-left", handleUserLeft);
        };
    }, [client, token, channelName, start]);

    const handleUserPublished = async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        setRemoteUsers((prevUsers) => ({
            ...prevUsers,
            [user.uid]: { user, mediaType },
        }));
    };

    const handleUserUnpublished = (user) => {
        if (remoteUsers[user.uid]) {
            const remoteUser = remoteUsers[user.uid];
            if (remoteUser.user.audioTrack) {
                remoteUser.user.audioTrack.stop();
            }
            if (remoteUser.user.videoTrack) {
                remoteUser.user.videoTrack.stop();
            }
        }

        setRemoteUsers((prevUsers) => {
            const updatedUsers = { ...prevUsers };
            delete updatedUsers[user.uid];
            return updatedUsers;
        });
    };

    const handleUserLeft = (user) => {
        handleUserUnpublished(user);
    };

 
const leaveChannel = async () => {
    if (start) {
        if (localAudioTrack) {
            localAudioTrack.close();
            setLocalAudioTrack(null);
        }
        if (localVideoTrack) {
            localVideoTrack.close();
            setLocalVideoTrack(null);
        }

        await client.unpublish([localAudioTrack, localVideoTrack]);
        await client.leave();

        setStart(false);
        setRemoteUsers({});

        // Assuming you have a function or API endpoint to call when leaving

    };
    try {
        console.log("try activated in leave button ")
        const session_uuid = localStorage.getItem('session_uuid');
        await axios.post(`${hostConfig.API_BASE}/patientleaveroom`, {
            // Your payload here, e.g.,
            timestamp: new Date().toISOString(),
            session_uuid: session_uuid
            
            // Include any other information you might need
        });
        console.log("sent request to backend");
    } catch (error) {
        console.error('Error sending leave channel notification:', error);
        // Handle error accordingly
        // Consider whether to show the user a message, log the error, etc.
    }
    localStorage.removeItem('therapist_uuid');
    localStorage.removeItem('session_uuid');
    navigate('/patientdashboard');
        };

    const videoRefs = useRef({});
    useEffect(() => {
        Object.entries(remoteUsers).forEach(([uid, { user, mediaType }]) => {
            if (mediaType === 'video' && videoRefs.current[uid]) {
                user.videoTrack.play(videoRefs.current[uid]);
            }
        });
    }, [remoteUsers]);

    return (
        <div className="Patient-Video-App">
            <div className="patient-remote-users">
                {Object.entries(remoteUsers).map(([uid, { user, mediaType }]) => (
                    <div className="remote-videos" key={uid} id={`user-container-${uid}`}
                        style={{
                            flex: '1 1 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10%',
                            overflow: 'hidden',
                            maxwidth: "1280px",
                            height: "780px"
                        }}>
                        {mediaType === 'video' && (
                            <div ref={(el) => (videoRefs.current[uid] = el)} style={{ width: "98%", height: "98%", borderRadius: '10%' }} />
                        )}
                    </div>
                ))}
            </div>
            <div id="patient-local-container" style={{
                borderStyle: "solid",
                borderRadius: '15px',
                overflow: 'hidden',
                width: "160px",
                height: "120px"
            }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
                <IconButton onClick={toggleMic} style={{ backgroundColor: isAudioMuted ? blue[500] : blue[200], color: 'white' }}>
                    {isAudioMuted ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
                <IconButton onClick={toggleVideo} style={{ backgroundColor: isVideoMuted ? red[500] : blue[200], color: 'white' }}>
                    {isVideoMuted ? <VideocamOffIcon /> : <VideocamIcon />}
                </IconButton>
                <IconButton onClick={leaveChannel} style={{ backgroundColor: red[600], color: 'white' }}>
                    <CallEndIcon />
                </IconButton>
                <Receiver />
            </div>
        </div>
    );
}

export default AgoraVideoCall;
