import React, { Component,useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
import CloudinaryUploadWidgetImage from "./TherapistPictureUpload";



class CloudinaryUploadWidget extends Component {
  
  
  componentDidMount() {
    const cloudName = "hkqlkwtmu"; // replace with your own cloud name
    const uploadPreset = "z4lendju"; // replace with your own upload preset

    // Remove the comments from the code below to add
    // additional functionality.
    // Note that these are only a few examples, to see
    // the full list of possible parameters that you
    // can add see:
    //   https://cloudinary.com/documentation/upload_widget_reference
    const therapistUUID = localStorage.uuid
    

    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudName,
        uploadPreset: uploadPreset,
        public_id: therapistUUID,
        // cropping: true, //add a cropping step
         showAdvancedOptions: true,  //add advanced options (public_id and tag)
         sources: [ "local", "url","google_drive"], // restrict the upload sources to URL and local files
        // multiple: false,  //restrict upload to a single file
         folder: "TherapistVideo", //upload files to the specified folder
        // tags: ["users", "profile"], //add the given tags to the uploaded files
        // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
         clientAllowedFormats: ["video"], //restrict uploading to image files only
        // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
        // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
        // theme: "purple", //change to a purple theme
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          document
            .getElementById("uploadedimage")
            .setAttribute("src", result.info.secure_url);
        }
      }
    );
    document.getElementById("upload_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );
  }
  

  render() {
    return (
      <div>
        <div>
          Video Upload
        </div>
      <button id="upload_widget" className="cloudinary-button">
       Upload
      </button>
      <div>
      <div>Image Upload</div>
      <CloudinaryUploadWidgetImage />
      </div>
      </div>
    );
  }
}

export default CloudinaryUploadWidget;

// {t('therapistdashboard.videoupload.uploadbutton')}
