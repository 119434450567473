import React, { useState } from "react";
import { Card, CardContent, CardMedia, Typography, Grid, IconButton, Box,useTheme, useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const cardData = [
    { image:'image1',therapist_name: "angela thorn",average_rating:5 },
    { image:'image1',therapist_name: "David Turner",average_rating:4.5 },
    { image:'image1',therapist_name: "Gojo Satoru",average_rating:3.5 },
    { image:'image1',therapist_name: "Geto Suguru",average_rating:4.3 },
    { image:'image1',therapist_name: "Geto Suguru",average_rating:4.3 },
    { image:'image1',therapist_name: "Geto Suguru",average_rating:4.3 },
    { image:'image1',therapist_name: "Geto Suguru",average_rating:4.3 },
    // Add more objects as needed

    
  ];
  

  const TherapistCards = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // This will be true if the screen width is less than 600px
  
    const [currentSlide, setCurrentSlide] = useState(0);
  
    // Adjust the number of items per page based on the screen size
    const itemsPerPage = isMobile ? 2 : 6; // Show 2 items per page on mobile, 6 items per page on desktop
    const maxSlides = Math.ceil(cardData.length / itemsPerPage);
  
    const handleNext = () => {
      setCurrentSlide((prev) => (prev + 1) % maxSlides);
    };
  
    const handleBack = () => {
      setCurrentSlide((prev) => (prev - 1 + maxSlides) % maxSlides);
    };
  
    const startIndex = currentSlide * itemsPerPage;
    const visibleCards = cardData.slice(startIndex, startIndex + itemsPerPage);
  
  
    return (
      <Box display="flex" alignItems="center">
              <IconButton onClick={handleBack} disabled={currentSlide === 0}>

          <ArrowBackIosNewIcon />
        </IconButton>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {visibleCards.map((item, index) => (
            <Grid item xs={12} sm={4} key={index}> {/* Use xs={12} for full width on small screens and sm={4} for 3 columns on small to medium screens */}
              <Card
                className="symptom-card"
                sx={{
                  backgroundColor: '#20CD8D',
                  width: '100%', // Use 100% width for better responsiveness
                  margin: 'auto', // Center the card if needed
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '200px' }} // Use sx prop for consistency
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={item.image}
                    className="card-media"
                    sx={{
                      border: '2px solid #F2F4E6',
                      borderRadius: '50%',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#F2F4E6', fontWeight: 'bold' }}>
                    {item.therapist_name}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="h7" sx={{ color: '#F2F4E6', fontWeight: 'bold' }}> {/* Note: 'h7' is not a valid variant in MUI */}
                    {item.average_rating}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <IconButton onClick={handleNext} disabled={currentSlide === maxSlides - 1}>

          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    );
  };
  

export default TherapistCards;
