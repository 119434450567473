import React, { useState, useEffect } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [isEnglish, setIsEnglish] = useState(i18n.language === 'ja');

  useEffect(() => {
    const lang = isEnglish ? 'en' : 'ja';
    i18n.changeLanguage(lang);
  }, [isEnglish, i18n]);

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item>
        <Typography variant="body2" onClick={() => toggleLanguage()} sx={{ cursor: 'pointer' }}>
          JA
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={isEnglish}
          onChange={toggleLanguage}
          name="languageSwitch"
          sx={{
            '& .MuiSwitch-track': {
              backgroundColor: isEnglish ? 'rgba(0, 0, 0, 0.38)' : 'blue', // Track color when "off"
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              // Change the color of the thumb and the track when "on"
              color: 'primary.main', // Thumb color when "on"
              '+ .MuiSwitch-track': {
                backgroundColor: 'primary.main', // Track color when "on"
              },
            },
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="body2" onClick={() => toggleLanguage()} sx={{ cursor: 'pointer' }}>
          EN
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LanguageSwitch;
