import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import "react-datepicker/dist/react-datepicker.css";
import BookingCalendar from './BookingCalendar';
import axios from 'axios';
import { hostConfig } from '../../config';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import TherapistVideoPlayer from "./TherapistVideo";
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import BookingsCalendarModal from './CalendarModal';

const BookingModal = ({ schedules = [], firstname, lastname, uuid, description }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalDate, setModalDate] = useState([]);
  const [timezone, setTimezone] = useState('Asia/Tokyo');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasCreditCard, setHasCreditCard] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.isLoggedIn === 'true');

  const schedulesArray = useMemo(() => {
    return schedules
      ? (Array.isArray(schedules)
        ? schedules
        : typeof schedules === 'string'
          ? schedules.split(',').map(s => s.trim())
          : [])
      : [];
  }, [schedules]);

  const convertedSchedules = useMemo(() => {
    if (schedulesArray.length > 0) {
      try {
        const converted = schedulesArray.map(schedule => {
          // Assume schedule is in UTC
          const dateInUTC = new Date(schedule + 'Z');
          // Format the date in the preferred timezone
          return formatInTimeZone(dateInUTC, timezone, "yyyy-MM-dd HH:mm:ss");
        });
        //console.log('Converted unavailable times:', converted);
        return converted;
      } catch (err) {
        console.error('Error converting schedules:', err);
        setError('Failed to process schedules. Please try again.');
        return [];
      }
    }
    return [];
  }, [schedulesArray, timezone]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    const preferredTimezone = localStorage.getItem('userTimezone');

    if (preferredTimezone) {
      setTimezone(preferredTimezone);
    }
    /*
    console.log('preferred timezone', preferredTimezone)
    console.log('Original schedules:', schedulesArray);
    console.log('Converted schedules in useEffect:', convertedSchedules);
*/
    setIsLoading(false);
  }, [schedulesArray, convertedSchedules]);

  const handleDateReceived = (date) => {
    setModalDate(date);
  };

  const handleTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    //console.log('Changing timezone from', timezone, 'to', newTimezone);
    setTimezone(newTimezone);
    sessionStorage.setItem('preferred_timezone', newTimezone);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    const booking_date = modalDate;

    if (isLoggedIn && localStorage.userUuid) {
      try {
        const creditCardResponse = await axios.get(`${hostConfig.API_BASE}/user/${localStorage.userUuid}/credit-card-status`);

        if (creditCardResponse.data.hasCreditCard) {
          const response = await axios.post(`${hostConfig.API_BASE}/bookings`, {
            booking_date,
            therapist_uuid: uuid,
            timezone: timezone
          });
          if (response.data.session_booked === true) {
            navigate({
              pathname: '/bookingcomplete',
              search: createSearchParams({
                booking_date: booking_date,
                first_name: firstname
              }).toString()
            });
          } else {
            setError('Booking failed. Please try again.');
          }
        } else {
          setHasCreditCard(false);
        }
      } catch (error) {
        console.error('Error occurred while booking:', error.response ? error.response.data : error.message);
        setError('An error occurred. Please try again.');
      }
    } else {
      setError('You need to log in or register an account to book a session.');
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">Book a Session with {firstname}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={open}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            maxWidth: '90vw',
            maxHeight: '90vh', // Set a maximum height
            bgcolor: '#ffffff',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            overflowY: 'auto', // Enable vertical scrolling
          }}>
            <h2>{firstname} {lastname}</h2>
            <TherapistVideoPlayer width={272} height={210} publicid={uuid} />
            <h5>{description}</h5>
            
            {!isLoggedIn && (
              <Typography color="error" sx={{ mt: 2, mb: 2 }}>
                You need to <a href="/patientlogin" style={{ color: 'inherit' }}>log in</a> or <a href="/register" style={{ color: 'inherit' }}>register an account</a> to book a session.
              </Typography>
            )}
            
            {isLoading ? (
              <p>Loading schedules...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <BookingsCalendarModal
                schedules={convertedSchedules}
                firstname={firstname}
                uuid={uuid}
                onDateSelect={handleDateReceived}
              />
            )}

            {!hasCreditCard && (
              <Typography color="error">
                You need to register a credit card in your account to book a session.
              </Typography>
            )}

          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

BookingModal.propTypes = {
  schedules: PropTypes.array,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  description: PropTypes.string,
};

BookingModal.defaultProps = {
  schedules: [],
};

export default BookingModal;