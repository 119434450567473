import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { hostConfig } from '../../config';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledGoogleButton = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  '& > div': {
    width: '100% !important',
    justifyContent: 'center',
  },
}));

function TherapistGlogin() {
  const navigate = useNavigate();

  const responseMessage = async (response) => {
    if (response.credential) {
      try {
        const res = await axios.post(`${hostConfig.API_BASE}/therapistglogin`, { credential: response.credential });
        console.log('Backend response:', res.data);
        if (res.data.user_validated === true) {
          console.log(res.data.user);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('userUuid', res.data.user);
          navigate('/therapistdashboard');
        } else {
          console.log('try again');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <StyledGoogleButton>
      <GoogleLogin
        clientId={process.env.G_CONTENT}
        onSuccess={responseMessage}
        onError={errorMessage}
        useOneTap
        theme="outline"
        size="large"
        text="signin_with"
        shape="rectangular"
        logo_alignment="left"
      />
    </StyledGoogleButton>
  );
}

export default TherapistGlogin;