import React from 'react';
import { Box, Grid, Typography, Button, Paper } from '@mui/material';
import therapistimage from './therapist-picture.png';
import { alpha } from '@mui/system';
import { useTranslation } from 'react-i18next';


const TherapistRecruitSection = () => {

  const { t,i18n} = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const subtitle1 = t('TherapistRecruit.subtitle1');
  const subtitle2 = t('TherapistRecruit.subtitle2');
  const subtitle3 = t('TherapistRecruit.subtitle3');
  const subtitle4 = t('TherapistRecruit.subtitle4');
  const message1 = t('TherapistRecruit.message1');
  const message2 = t('TherapistRecruit.message2');
  const message3 = t('TherapistRecruit.message3');
  const message4 = t('TherapistRecruit.message4');



  const recruitMessages = [
    { title: subtitle1, message: message1 },
    { title: subtitle2, message: message2},
    { title: subtitle3, message: message3},
    { title: subtitle4, message: message4 },
  ];
  const sectionHeight = '40vh'
    return (
    <Box sx={{ 
      display: 'flex', 
      bgcolor: '#F2F4E6', 
      paddingTop: '20vh',
      minHeight: sectionHeight }}> {/* Set min-height to the viewport height */}
      <Grid container>
        <Grid item xs={12} md={6} sx={{ 
          bgcolor: '#0A6621', display: 'flex', flexDirection: 'column', 
          alignItems: 'center', justifyContent: 'center', minHeight: sectionHeight }}> {/* Set min-height to match the right side */}
          <Box
            component="img"
            src={therapistimage}
            alt="Therapist"
            sx={{ maxWidth: '70%', maxHeight: '70%', objectFit: 'contain' }}
          />
          <Typography variant="h4" gutterBottom sx={{ 
            color: '#F2F4E6', 
            position: 'absolute',
            bgcolor: alpha('#0A6621', 0.5)
            }}> {/* Position text at the bottom */}
            {t('TherapistRecruit.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} sx={{ p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: sectionHeight }}> {/* Set min-height to match the left side */}
          {recruitMessages.map((item, index) => (
            <Paper key={index} sx={{ my: 2, p: 2, bgcolor: '#F2F4E6', boxShadow: 'none', borderBottom: '0.5px solid #0A6621' }} elevation={0}>
              <Typography variant="h6" gutterBottom sx={{ color: '#0A6621', fontWeight: 'bold', textAlign: 'left' }}>
                {item.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#0A6621' }}>
                {item.message}
              </Typography>
            </Paper>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
            <Button variant="contained" sx={{ bgcolor: '#0A6621', color: '#F2F4E6', '&:hover': { bgcolor: '#F2F4E6', color: '#0A6621' } }}>
            {t('TherapistRecruit.button')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TherapistRecruitSection;
