import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";

const BookingCalendar = ({ schedules, onDateSelect }) => {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const { t } = useTranslation();
/*
  // Example schedules
  const exampleSchedules = [
    "2024-07-04 13:00:00",
    "2024-07-05 09:30:00",
    "2024-07-06 16:45:00",
    "2024-07-07 14:15:00",
    "2024-07-08 11:00:00"
  ];

  // Use this line to test with the example schedules
  const disabledDateTimes = exampleSchedules;
*/
  // Uncomment this line to use the schedules passed as props
   const disabledDateTimes = schedules;

  console.log(selectedDate, "selectDate");
  console.log(schedules, "schedules passed on");

  // Helper function to generate an array of times within one hour after a given time
  const generateDisabledTimes = (disabledDateTime) => {
    const disabledTimes = [];
    let time = dayjs(disabledDateTime);
    for (let i = 1; i <= 4; i++) {
      time = time.add(15, 'minute');
      disabledTimes.push(time.format("YYYY-MM-DD HH:mm:ss"));
    }
    console.log(disabledTimes,'disabled times');
    return disabledTimes;
  };

  const shouldDisableTime = (dateTime) => {
    if (disabledDateTimes === null) {
      return false; // Don't disable time when the disabledDateTimes is null
    }

    const dateTimeFormatted = dayjs(dateTime).format("YYYY-MM-DD HH:mm:ss");

    return disabledDateTimes.some((disabledDateTime) => {
      const disabledDateFormatted = dayjs(disabledDateTime).format("YYYY-MM-DD HH:mm:ss");
      const disabledHour = dayjs(disabledDateTime).hour();
      const dateHour = dayjs(dateTime).hour();

      // Check if the exact time is disabled
      if (dateTimeFormatted === disabledDateFormatted) {
        return true;
      }

      // Only disable times within one hour if they fall in a different hour
      if (disabledHour !== dateHour) {
        return generateDisabledTimes(disabledDateTime).includes(dateTimeFormatted);
      }

      return false;
    });
  };

  // Handling the date change for selected date
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  const nineAM = dayjs().set("hour", 9).startOf("hour");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={t('searchpage.bookingcalendar.title')}
        disablePast
        onChange={handleDateChange}
        value={selectedDate}
        variant="inline"
        shouldDisableTime={shouldDisableTime}
        timeSteps={{ minutes: 15 }}
        minTime={nineAM}
        format="YYYY-MM-DD HH:mm:ss"
        ampm={false}
        TextFieldComponent={() => null}
        slotProps={{
          actionBar: {
            actions: ["Book"]
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default BookingCalendar;
