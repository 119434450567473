import React, { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, isSameDay, isBefore, isToday } from 'date-fns';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { hostConfig } from '../../config';
import axios from 'axios';
import BookingComplete from './BookingCompletePage';
import { useTranslation } from "react-i18next";

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const BookingsCalendarModal = (therapist_uuid) => {
    const navigate = useNavigate();
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [timezone, setTimezone] = useState('Asia/Tokyo');
    const [open, setOpen] = useState(true);
    const [therapistData, setTherapistData] = useState([]);
    const [convertedReservedDates, setConvertedReservedDates] = useState([]);
    const [error, setError] = useState('');
    const [hasCreditCard, setHasCreditCard] = useState(true);

    const { t, i18n } = useTranslation();
    const handleClose = () => setOpen(false);
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

    const handleTimezoneChange = (event) => {
        const newTimezone = event.target.value;
        setTimezone(newTimezone);
        sessionStorage.setItem('preferred_timezone', newTimezone);
        convertReservedDates(therapistData, newTimezone);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${hostConfig.API_BASE}/therapistavailability`, {
                    params: { therapist_uuid }
                });
                
                let processedData;
                if (typeof response.data === 'string') {
                    processedData = response.data.split(', ');
                } else if (Array.isArray(response.data)) {
                    processedData = response.data;
                } else if (typeof response.data === 'object' && response.data.booked_dates) {
                    processedData = response.data.booked_dates.split(', ');
                } else {
                    console.error("Unexpected data format:", response.data);
                    processedData = [];
                }

                setTherapistData(processedData);
                convertReservedDates(processedData, timezone);
            } catch (error) {
                console.error("Error fetching therapist data:", error);
            }
        };
    
        fetchData();
    }, [therapist_uuid]);

    const convertReservedDates = (dateStrings, targetTimezone) => {
        const converted = dateStrings.map(dateTimeString => {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            const [hours, minutes] = timePart.split(':').map(Number);
            
            // Create a date in UTC
            const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));
            
            // Convert to target timezone
            const targetDate = new Date(utcDate.toLocaleString('en-US', { timeZone: targetTimezone }));
            
            return {
                date: targetDate,
                time: `${String(targetDate.getHours()).padStart(2, '0')}:${String(targetDate.getMinutes()).padStart(2, '0')}`
            };
        });
        
        setConvertedReservedDates(converted);
    };

    const isTimeReservedForDate = (date, time) => {
        return convertedReservedDates.some(reservation => {
            const adjustedReservationDate = new Date(
                reservation.date.getFullYear(),
                reservation.date.getMonth(),
                reservation.date.getDate()
            );
    
            const formattedReservationDate = format(adjustedReservationDate, 'yyyy-MM-dd');
            const formattedSelectedDate = format(date, 'yyyy-MM-dd');
    
            return formattedReservationDate === formattedSelectedDate && reservation.time === time;
        });
    };
    
    const handleReservationSubmit = async () => {
        const [hours, minutes] = selectedTime.split(':').map(Number);
        const reservationDateTime = new Date(selectedDate);
        reservationDateTime.setHours(hours, minutes, 0, 0);
        const formattedReservationDateTime = reservationDateTime.toISOString();
        const booking_date = formattedReservationDateTime;
        
        if (localStorage.userUuid) {
            try {
                // Check credit card status
                const creditCardResponse = await axios.get(`${hostConfig.API_BASE}/user/${localStorage.userUuid}/credit-card-status`);

                if (creditCardResponse.data.hasCreditCard) {
                    // Proceed with booking
                    const response = await axios.post(`${hostConfig.API_BASE}/bookings`, {
                        booking_date,
                        therapist_uuid: therapist_uuid.uuid,
                        timezone: timezone
                    });

                    if (response.data.session_booked === true) {
                        handleClose();
                        navigate({
                            pathname: '/bookingcomplete',
                            search: createSearchParams({
                                booking_date: booking_date,
                            }).toString()
                        });
                    } else {
                        setError('Booking failed. Please try again.');
                    }
                } else {
                    setHasCreditCard(false);
                    setError('You need to add a credit card to your account before booking.');
                }
            } catch (error) {
                console.error('Error making reservation:', error);
                setError('An error occurred. Please try again.');
            }
        } else {
            setError('You need to log in or register an account to book a session.');
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime('');
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    }

    const handleMonthChange = (direction) => {
        setCurrentMonth(prevMonth => addMonths(prevMonth, direction));
    }

    return (
        <div className="reservation">
            <div className="month-navigation">
                <button onClick={() => handleMonthChange(-1)}>←</button>
                <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
                <button onClick={() => handleMonthChange(1)}>→</button>
            </div>
            <div className="ModalCalendar">
                {daysInMonth.map((date) => (
                    <button
                        key={format(date, 'yyyy-MM-dd')}
                        onClick={() => handleDateClick(date)}
                        className={isSameDay(selectedDate, date) ? 'selected' : ''}
                    >
                        {format(date, 'd')}
                    </button>
                ))}
            </div>
            {selectedDate && (
                <div className="time-slots">
                    {['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00']
                        .filter(slot => !isTimeReservedForDate(selectedDate, slot))
                        .map(slot => (
                            <button
                                key={slot}
                                onClick={() => handleTimeClick(slot)}
                                className={selectedTime === slot ? 'selected' : ''}
                            >
                                {slot}
                            </button>
                        ))}
                </div>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2, mb: 2 }}>
                <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="timezone-select-label">Timezone</InputLabel>
                    <Select
                        labelId="timezone-select-label"
                        id="timezone-select"
                        value={timezone}
                        label="Timezone"
                        onChange={handleTimezoneChange}
                    >
                        <MenuItem value="Australia/Sydney">Australia (Sydney)</MenuItem>
                        <MenuItem value="Asia/Tokyo">Japan</MenuItem>
                        <MenuItem value="America/New_York">US (New York)</MenuItem>
                        <MenuItem value="America/Los_Angeles">US (Los Angeles)</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            
            {error && <div className="error-message">{error}</div>}
            
            <button 
                className="reserve-button"
                onClick={handleReservationSubmit}
                disabled={!selectedDate || !selectedTime}
            >
                {selectedDate && selectedTime ? `${t('patientvideo.calendarmodal.button')} ${format(selectedDate, 'MMMM d, yyyy')} ${selectedTime}` : `${t('patientvideo.calendarmodal.buttonother')}`}
            </button>
            
            {!hasCreditCard && (
                <div className="credit-card-warning">
                    Please add a credit card to your account to make a booking.
                </div>
            )}
        </div>
    );
}

export default BookingsCalendarModal;