import React, { useState } from 'react';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Divider
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import { hostConfig } from '../../config';
import Glogin from './GoogleLogin';
import LineLoginButton from '../../TherapistApps/LogIn/LineLoginButton';
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  margin: '0 auto',
  padding: theme.spacing(3),
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: theme.shape.borderRadius,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#0A6621',
  '&:hover': {
    backgroundColor: '#085219',
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  textTransform: 'none',
}));



const PatientLoginForm = () => {
  const [patientPassword, setPatientPassword] = useState('');
  const [patientEmail, setPatientEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationStage, setIsVerificationStage] = useState(false);
  const [error, setError] = useState('');
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false); // Reset login error at the start of submission
    if (!isVerificationStage) {
      try {
        const response = await axios.post(`${hostConfig.API_BASE}/patientlogin`, { patientEmail, patientPassword });
        
        console.log(response.data,"response.data from patientlogin");
        if (response.data.requires2FA) {
          //if (response.data.data.testobject.requires2FA) {
          setIsVerificationStage(true);
        } else {
          //completeLogin(response.data);
        }
      } catch (error) {
        console.error('Login error:', error);
        setError(error.response.data.message);
        setLoginError(true); // Activate login error state
      }
    } else {
      try {
        const response = await axios.post(`${hostConfig.API_BASE}/patientverify2fa`, { patientEmail, verificationCode });
        console.log(response.data,'response.data')
        if (response.data.success) {
          completeLogin(response.data.uuid, response.data.timezone);
        } else {
          setError('Invalid verification code');
          setLoginError(true); // Activate login error state
        }
      } catch (error) {
        console.error('Verification error:', error);
        setError(error.response.data.message);
        setLoginError(true); // Activate login error state
      }
    }
  };

  const completeLogin = (uuid,timezone) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userUuid', uuid);
    localStorage.setItem('userTimezone', timezone); // Store the timezone

    navigate('/patientdashboard');
  };

  return (
    <Grid container justifyContent="center" sx={{ minHeight: '100vh', alignItems: 'center' }}>
      <StyledCard>
        <CardContent>
          <Typography variant="h5" component="h1" gutterBottom align="center">
            {t('patientlogin.title')}
          </Typography>
          <form onSubmit={handleSubmit}>
            {!isVerificationStage ? (
              <>
                <TextField
                  error={loginError}
                  helperText={loginError ? error : ''}
                  placeholder="Email"
                  label="Email"
                  variant="outlined"
                  type="text"
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  error={loginError}
                  helperText={loginError ? error : ''}
                  placeholder="Password"
                  label={t('patientlogin.password')}
                  variant="outlined"
                  type="password"
                  value={patientPassword}
                  onChange={(e) => setPatientPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
                <StyledButton type="submit" variant="contained" fullWidth size="large">
                  {t('patientlogin.login')}
                </StyledButton>
              </>
            ) : (
              <>
                <TextField
                  error={loginError}
                  helperText={loginError ? error : ''}
                  placeholder="Verification Code"
                  label="Verification Code"
                  variant="outlined"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
                <StyledButton type="submit" variant="contained" fullWidth size="large">
                  {t('patientlogin.verify')}
                </StyledButton>
              </>
            )}
          </form>
          {error && <Typography color="error" style={{ marginTop: '10px' }}>{error}</Typography>}
          {!isVerificationStage && (
            <Box mt={3}>
              <Divider>
                <Typography variant="body2" color="textSecondary">
                  {t('patientlogin.or')}
                </Typography>
              </Divider>
              <Box mt={2}>
                <Glogin />
                <LineLoginButton userType="patient"/>
              </Box>
              <Typography variant="body2" align="center" style={{ marginTop: '16px' }}>
                {t('patientlogin.noaccount')} <Link to="/patientregister">{t('patientlogin.here')}</Link>
              </Typography>
            </Box>
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

export default PatientLoginForm;